import { datadogLogs } from "@datadog/browser-logs";
import { useQuery } from "@tanstack/react-query";
import {
  fetchAnonymousXtmDelegatedJobsTask,
  fetchAnonymousXtmProjects,
  fetchAnonymousXtmStepProgress,
  fetchAnonymousXtmTask,
} from "anonymous/api/xtm.api";
import { XtmProjectDto } from "api/api.typing";
import { AxiosError } from "axios";
import { toastContent } from "components/toastContent";
import { t } from "i18next";
import orderBy from "lodash/orderBy";
import { CatSteps } from "pages/project/common/taskBase.typing";
import { mapToTaskBase } from "pages/project/common/taskBase/taskBaseMapper";
import { Task } from "pages/project/task/task.typing";
import { calculateProgress } from "query/task.query";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { formatServerError } from "utils/format";

export const useAnonymousXtmTask = (token: string) => {
  const {
    data = {} as Task,
    isLoading,
    error,
  } = useQuery<Task, AxiosError>({
    queryKey: ["anonymousXtmTask", token],
    queryFn: () =>
      fetchAnonymousXtmTask(token).then((taskDto) => {
        const taskBase = mapToTaskBase(taskDto);
        return { ...taskBase, status: taskDto.status, shouldDisplayQAForm: taskDto.qaFormRequirement === 2 };
      }),
  });

  useEffect(() => {
    if (error) {
      datadogLogs.logger.error("failed to fetch anonymous xtm task");
    }
  }, [error]);

  return { selectedTask: data, isLoading, error };
};

export const useAnonymousXtmData = (token: string, isTaskOngoing: boolean) => {
  const { isFetching: isFetchingProjectList, projects, error } = useAnonymousXtmProjectList(token);
  const { steps, isFetching: isFetchingSteps } = useAnonymousXtmStepProgress(
    token,
    isTaskOngoing && projects.length > 0
  );
  return { steps, projects, isFetching: isFetchingProjectList || isFetchingSteps, error };
};

const useAnonymousXtmStepProgress = (token: string, isShouldFetch: boolean) => {
  const { data, isFetching, error } = useQuery<CatSteps, AxiosError>({
    queryKey: ["anonymousXtmStepProgress", token],
    queryFn: async ({ signal }) => {
      const response = await fetchAnonymousXtmStepProgress(token, signal);
      const totalSegments = response.totalStepsProgress.totalSegments;
      const doneSegments = response.totalStepsProgress.segmentsDone;
      const result: CatSteps = {
        totalSegments,
        doneSegments,
        progression: calculateProgress(doneSegments, totalSegments),
        details: orderBy(
          response.stepsProgress.map((value) => ({
            documentName: value.fileName,
            doneSegments: value.segmentsDone,
            totalSegments: value.totalSegments,
            progression: calculateProgress(value.segmentsDone, value.totalSegments),
          })),
          "progression",
          "asc"
        ),
      };
      return result;
    },
    enabled: isShouldFetch,
    refetchOnWindowFocus: "always",
  });

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch xtp step progress");
      if (message) {
        toast.error(toastContent(t("translation:task.error-title.xtm-step-progress"), message), {
          id: "task-xtm-error",
        });
      }
    }
  }, [error]);

  return { steps: data, isFetching, error };
};

export const useAnonymousXtmProjectList = (token: string) => {
  const {
    data = [],
    isLoading,
    isFetching,
    error,
  } = useQuery<XtmProjectDto[], AxiosError>({
    queryKey: ["anonymousXtmProjectList", token],
    queryFn: () => fetchAnonymousXtmProjects(token),
    enabled: !!token,
  });
  const hasProject = !isLoading && data.length > 0;

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch anonymous projects");
      if (message) {
        toast.error(toastContent(t("translation:task.error-title.xtm-project-list"), message), {
          id: "xtmProjects-fetch-error",
        });
      }
    }
  }, [error]);

  return { projects: data, hasProject, isFetching, error };
};

export const useAnonymousDelegatedXtmJobLink = (token: string) => {
  const {
    data = null,
    error,
    isLoading,
    isSuccess,
  } = useQuery<string | null, AxiosError>({
    queryKey: ["anonymousXtmDelegatedTask", token],
    queryFn: () => fetchAnonymousXtmDelegatedJobsTask(token),
    retry: false,
    staleTime: 0, // no cache.
  });

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch anonymous project link");
      if (message) {
        toast.error(toastContent(t("translation:task.detail.jobLinks.xtm.open-editor-error"), message), {
          id: "xtmProjects-fetch-error",
        });
      }
    }
  }, [error]);

  return { url: data, isLoading, isSuccess };
};
