import api from "api/api";
import {
  CatStepsDto,
  CatToolProjectLinkDto,
  FileDownloadUrlDtoResponse,
  IdName,
  MemSourceProjectLinkDto,
  MemSourceProjectLinksDtoResponse,
  MemsourceTaskJobsCompletion,
  QAFormDto,
  TaskBaseFileDto,
  TaskBaseFileDtoResponse,
  TaskDtoResponse,
  TaskInstructionDto,
  Translate5ProjectLinksDtoResponse,
  USER_TYPES,
  UserTypeCode,
  XtmProjectDto,
  XtmProjectsDtoResponse,
  ZipFullDownloadDtoResponse,
} from "api/api.typing";
import { requestDownloadFile } from "api/utils";
import { AxiosError, AxiosResponse } from "axios";
import orderBy from "lodash/orderBy";
import { DateTime, Duration } from "luxon";
import { TaskFileUploadType } from "pages/project/common/taskBase.typing";
import { toHuman } from "utils/format";
import { LanguageType, SUPPORTED_LANGUAGES } from "utils/language";

export const fetchTasks = async (userType: UserTypeCode, language: LanguageType): Promise<TaskDtoResponse> => {
  const endpoint = userType === USER_TYPES.customer ? "/api/tasks/customerTasks" : "/api/tasks";
  const response = await api.get<TaskDtoResponse>(
    `${endpoint}?translationLanguageCode=${SUPPORTED_LANGUAGES[language].tempoCode}`
  );

  return response.data;
};

export const requestDeliverTask = async (
  taskId: string,
  fullDelivery: boolean,
  comment?: string,
  qaFormRatings?: Array<{ key: number; value: number | null }>,
  catToolId?: number,
  noteDelivery?: boolean
): Promise<AxiosResponse<void>> => {
  const fileTypeQueryParam = noteDelivery ? `&noteDelivery=${noteDelivery}` : "";

  return await api.put<void>(
    `/api/tasks/${taskId}/deliver?fullDelivery=${fullDelivery}&catToolId=${catToolId}${fileTypeQueryParam}`,
    {
      comment,
      qaFormRatings,
    }
  );
};

export const fetchDeliveredFiles = async (taskId: string): Promise<TaskBaseFileDto[]> => {
  const response = await api.get<TaskBaseFileDtoResponse>(`/api/tasks/${taskId}/deliveredFiles`);
  const files = response?.data?.deliveredFiles ?? [];
  return orderBy(files, "creationTime", "desc");
};

export const fetchMemSourceProjectLinks = async (
  taskId: string,
  signal?: AbortSignal
): Promise<MemSourceProjectLinkDto[]> => {
  const response = await api.get<MemSourceProjectLinksDtoResponse>(`/api/tasks/${taskId}/memsourceProjectLinks`, {
    signal,
  });
  return response?.data?.memsourceProjectLinks ?? [];
};

export const fetchTranslate5ProjectLinks = async (
  taskId: string,
  signal?: AbortSignal
): Promise<CatToolProjectLinkDto[]> => {
  const response = await api.get<Translate5ProjectLinksDtoResponse>(`/api/tasks/${taskId}/translate5ProjectLinks`, {
    signal,
  });
  return response?.data?.catToolProjectLinks ?? [];
};

export const fetchXtmProject = async (taskId: string, signal?: AbortSignal): Promise<XtmProjectDto[]> => {
  const response = await api.get<XtmProjectsDtoResponse>(`/api/tasks/${taskId}/xtmProjects`, { signal });
  return response?.data?.xtmProjects ?? [];
};

export const requestZipFullTaskDownload = async (
  taskId: string,
  language: LanguageType,
  hubConnectionId: string
): Promise<ZipFullDownloadDtoResponse> => {
  const response = await api.post(`/api/tasks/${taskId}/zipFullDownload`, {
    taskId,
    languageCode: SUPPORTED_LANGUAGES[language].tempoCode,
    hubConnectionId,
  });

  return response.data;
};

export const requestDownloadFullTaskZip = async (taskId: string, guid: string): Promise<void> => {
  const response = await api.get<FileDownloadUrlDtoResponse>(`/api/tasks/${taskId}/fullDownloadUrl?guid=${guid}`);
  await requestDownloadFile(response.data.downloadUrl, { name: "file", extension: "zip" });
};

export const requestDownloadPurchaseOrder = async (taskId: string) => {
  const response = await api.get<FileDownloadUrlDtoResponse>(`/api/tasks/${taskId}/purchaseOrderDownloadUrl`);
  await requestDownloadFile(response.data.downloadUrl, { name: "file", extension: "pdf" });
};

export const requestDownloadSupplierPreInvoice = async (preInvoiceId: number) => {
  const response = await api.get<FileDownloadUrlDtoResponse>(`/api/SupplierPreInvoices/${preInvoiceId}/exportUrl`);
  await requestDownloadFile(response.data.downloadUrl, { name: "file", extension: "xlsx" });
};

export async function uploadToS3SingleTaskFile(
  {
    file,
    presignedUrl,
  }: {
    file: File;
    presignedUrl: string;
  },
  onUploading: (abort: AbortController) => void,
  onProgress: (progress: number, remainingTime?: string) => void,
  onUploaded: () => void,
  onFailed: (isCancellation: boolean) => void
): Promise<void> {
  try {
    const forwardUrl = import.meta.env.PROD
      ? presignedUrl
      : presignedUrl.replace("https://tempo-dev-01.s3.eu-central-1.amazonaws.com", "/forward");
    const abort = new AbortController();
    onUploading(abort);
    const startTime = DateTime.now();
    await api.put(forwardUrl, file.slice(), {
      onUploadProgress: (ev) => {
        const total = ev.total ?? 1;
        const uploadSpeed = ev.loaded / DateTime.now().diff(startTime, "seconds").toMillis();
        const remainingTime = toHuman(Duration.fromMillis((total - ev.loaded) / uploadSpeed));
        onProgress(Math.round((ev.loaded * 100) / total), remainingTime);
      },
      timeout: 0,
      signal: abort.signal,
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });
    onUploaded();
  } catch (error) {
    const isCancellation = (error as AxiosError)?.code === "ERR_CANCELED";
    onFailed(isCancellation);
  }
}

export const getPreSignedUrl = async (
  taskId: string,
  fileNames: string[],
  fileType: TaskFileUploadType
): Promise<Array<{ fileName: string; uploadUrl: string }> | null> => {
  const response = await api.post(`/api/tasks/${taskId}/buildPreSignedUrls`, {
    fileNames,
    fileType,
  });

  return response.data?.value?.results ?? null;
};

export const uploadSingleTaskFile = async (
  taskId: string,
  file: File,
  fileType: TaskFileUploadType,
  onUploading: (abort: AbortController) => void,
  onProgress: (progress: number, remainingTime?: string) => void,
  onUploaded: () => void,
  onFailed: (isCancellation: boolean) => void
): Promise<void> => {
  try {
    const abort = new AbortController();
    onUploading(abort);
    const url = `/api/tasks/${taskId}/uploadFile?fileType=${fileType}&fileName=${encodeURIComponent(file.name)}`;
    const formData = new FormData();
    formData.append(file.name, file);
    const startTime = DateTime.now();
    await api.post(url, formData, {
      onUploadProgress: (ev) => {
        const total = ev.total ?? 1;
        const uploadSpeed = ev.loaded / DateTime.now().diff(startTime, "seconds").toMillis();
        const remainingTime = toHuman(Duration.fromMillis((total - ev.loaded) / uploadSpeed));
        onProgress(Math.round((ev.loaded * 100) / total), remainingTime);
      },
      timeout: 0,
      signal: abort.signal,
    });
    onUploaded();
  } catch (error) {
    const isCancellation = (error as AxiosError)?.code === "ERR_CANCELED";
    onFailed(isCancellation);
  }
};

export const fetchQAForm = async (taskId: string): Promise<QAFormDto> => {
  const response = await api.get<QAFormDto>(`/api/tasks/${taskId}/qaform`);
  return response.data;
};

export const fetchSupplierContacts = async (): Promise<IdName[]> => {
  const url = `/api/tasks/supplierContacts`;
  const response = await api.get<IdName[]>(url);
  return response?.data;
};

export const updateSupplierContact = async (taskId: string, supplierContactId: number): Promise<void> => {
  const url = `/api/tasks/${taskId}/supplierContacts`;
  await api.put(url, { supplierContactId: supplierContactId });
};

export const fetchXtmStepsProgress = async (taskId: string, signal?: AbortSignal): Promise<CatStepsDto> => {
  const result = await api.get<CatStepsDto>(`/api/tasks/${taskId}/xtmStepsProgress`, { signal });
  return result.data;
};

export const fetchTaskInstructions = async (
  taskId: string,
  language: LanguageType,
  signal?: AbortSignal
): Promise<TaskInstructionDto[]> => {
  const result = await api.get(
    `/api/tasks/${taskId}/instructions?languageCode=${SUPPORTED_LANGUAGES[language].tempoCode}`,
    { signal }
  );
  return result.data.instructions;
};

export const fetchMemSourceStepsProgress = async (taskId: string, signal?: AbortSignal): Promise<CatStepsDto> => {
  const response: AxiosResponse<CatStepsDto> = await api.get(`/api/tasks/${taskId}/memsourceStepsProgress`, {
    signal,
  });
  return response.data;
};

export const fetchMemsourceJobsCompletion = async (
  taskId: string,
  signal?: AbortSignal
): Promise<MemsourceTaskJobsCompletion> => {
  const response: AxiosResponse<MemsourceTaskJobsCompletion> = await api.get(
    `/api/tasks/${taskId}/memsourceTaskJobsCompletion`,
    {
      signal,
    }
  );
  return response.data;
};
