import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Fields } from "components/typings";
import { ForwardedRef, InputHTMLAttributes, forwardRef, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "required"> {
  id: string;
  label: string;
  type: "email" | "password" | "text";
  hideOnDataDog?: boolean;
  error?: string;
}

type InputFormProps<FormModel extends Fields> = Props &
  Omit<ReturnType<UseFormRegister<FormModel>>, "name"> & {
    name: string;
  };

function InputFormInner<T extends Fields>(
  { id, type, label, error, className, name, hideOnDataDog = false, ...props }: InputFormProps<T>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const hasError = typeof error === "string" && error.length > 0;
  const isPassword = type === "password";
  const computedType = !isPassword ? type : showPassword ? "text" : "password";

  return (
    <div>
      <label htmlFor={id} data-test={`${id}-label`} className="block font-sans text-sm font-medium">
        {label}
      </label>
      <div className="mt-2">
        <div className={cn({ relative: hasError || isPassword, "rounded-md shadow-sm": hasError })}>
          <input
            {...props}
            ref={ref}
            id={id}
            data-test={`${id}-input`}
            aria-invalid={hasError}
            aria-describedby={hasError ? `${id}-error` : undefined}
            type={computedType}
            data-dd-privacy={hideOnDataDog ? "mask" : ""}
            name={name}
            className={cn(
              "block w-full appearance-none rounded-md shadow-sm focus:outline-none sm:text-sm",
              "dark:border-slate-700 dark:bg-slate-600 dark:text-slate-200",
              hasError
                ? "border-red-300 pr-10 text-red-900 placeholder:text-red-300 focus:border-red-500 focus:ring-red-500 dark:border-red-500"
                : "border-gray-300 placeholder:text-slate-400 focus:border-ribbon-400 focus:ring-ribbon-400",
              className
            )}
          />
          <div className="absolute inset-y-0 right-0 flex flex-row items-center space-x-1 pr-3">
            <>
              {isPassword && (
                <span>
                  {showPassword ? (
                    <EyeSlashIcon
                      className={`size-5 hover:cursor-pointer ${hasError ? "text-red-500" : ""}`}
                      aria-hidden="true"
                      aria-label={t("common.show-password")}
                      aria-controls={id}
                      data-test={`${id}-hide-pwd`}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <EyeIcon
                      className={`size-5 hover:cursor-pointer ${hasError ? "text-red-500" : ""}`}
                      aria-hidden="true"
                      aria-label={t("common.hide-password")}
                      aria-controls={id}
                      data-test={`${id}-show-pwd`}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setShowPassword(true)}
                    />
                  )}
                </span>
              )}
              {hasError && (
                <span className="pointer-events-none">
                  <ExclamationCircleIcon
                    className="size-5 text-red-500"
                    aria-hidden="true"
                    data-test={`${id}-error-icon`}
                  />
                </span>
              )}
            </>
          </div>
        </div>
        {hasError ? (
          <span
            className="mt-1 text-sm text-red-600 dark:text-red-500"
            id={`${id}-error`}
            data-test={`${id}-error`}
            role="alert"
          >
            {error}
          </span>
        ) : null}
      </div>
    </div>
  );
}

const InputForm = forwardRef(InputFormInner);

export default InputForm;
