import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Fields } from "components/typings";
import { ForwardedRef, forwardRef, TextareaHTMLAttributes, useState } from "react";
import { cn } from "utils/ui";
import { FormFieldType } from "./formfield.typing";

type Props<FormModel extends Fields> = Omit<
  FormFieldType<FormModel> & TextareaHTMLAttributes<HTMLTextAreaElement>,
  "name"
> & {
  name: string;
  maxCharLimit?: number;
};

function InnerTextAreaFormField<M extends Fields>(
  { id, label, error, rows = 4, className = "", ...props }: Props<M>,
  ref: ForwardedRef<HTMLTextAreaElement>
) {
  const [nbrOfChars, setNbrOfChars] = useState<number>(typeof props.value === "string" ? props.value.length : 0);
  const hasError = error !== undefined && error !== null && error.length > 0;
  const shouldCheckMaxCharLimit = props.maxCharLimit !== undefined && props.maxCharLimit > 0;

  return (
    <div>
      <label
        htmlFor={`${id}`}
        data-test={`${id}-label`}
        className="text-color-secondary block font-sans text-sm font-medium"
      >
        {label}
      </label>
      <div className="mt-2">
        <div className={cn({ "relative rounded-md shadow-sm": hasError })}>
          <textarea
            {...props}
            onChange={(e) => {
              props.onChange(e);
              shouldCheckMaxCharLimit && setNbrOfChars(e.target.value.length);
            }}
            ref={ref}
            rows={rows}
            data-test={`${id}-input`}
            aria-invalid={hasError}
            aria-describedby={hasError ? `${id}-error` : undefined}
            id={id}
            className={cn(
              "block w-full rounded-md shadow-sm focus:outline-none sm:text-sm",
              "dark:border-slate-700 dark:bg-slate-600 dark:text-slate-200 dark:focus:ring-2",
              hasError
                ? "border-red-300 text-red-900 placeholder:text-red-300 focus:border-red-500 focus:ring-red-500"
                : "border-gray-300 placeholder:text-slate-400 focus:border-ribbon-400 focus:ring-1 focus:ring-ribbon-400",
              className
            )}
          />
          {hasError && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="size-5 text-red-500"
                aria-hidden="true"
                data-test={`${id}-error-icon`}
              />
            </div>
          )}
        </div>
        {shouldCheckMaxCharLimit ? (
          <div
            className={cn("text-color-secondary mt-1 flex flex-row-reverse text-sm leading-4", {
              "text-red-600 dark:text-red-500": nbrOfChars > (props.maxCharLimit ?? 0),
            })}
          >
            {nbrOfChars}/{props.maxCharLimit}
          </div>
        ) : null}
        {hasError ? (
          <span
            className="mt-1 text-sm text-red-600 dark:text-red-500"
            id={`${id}-error`}
            data-test={`${id}-error`}
            role="alert"
          >
            {error}
          </span>
        ) : null}
      </div>
    </div>
  );
}

const TextAreaFormField = forwardRef(InnerTextAreaFormField);
export default TextAreaFormField;
