import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  children: ReactNode;
  title: ReactNode;
  onClose: () => void;
}

export const SideBar = ({ children, title, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="m-4 w-full lg:max-w-6xl lg:flex-1">
      <div className="text-color-base bg-color-base sticky top-0 rounded border border-slate-200 dark:border-slate-800">
        <div className="flex flex-col px-4 py-6 sm:px-6">
          {/*Header*/}
          <div className="mb-4 flex items-start justify-between">
            <div className="flex flex-1 flex-col space-y-2 sm:flex-row sm:items-center sm:space-x-2 sm:space-y-0">
              {title}
            </div>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                onClick={onClose}
                data-test="sidebar-close-panel"
                className="offset-ring-500 rounded-md text-slate-400 outline-0 hover:text-slate-500 dark:hover:text-slate-50"
              >
                <span className="sr-only">{t("task-base.sidebar.closePanel")}</span>
                <XMarkIcon className="size-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
