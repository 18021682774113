import { Button } from "components/ui/button";
import { UploadIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { isFilesUploading, useFileUploadStore } from "store/useFileUploadStore";

interface Props {
  onClick: () => void;
  taskCode: string;
  canUpload: boolean;
}

const TaskBaseFilesUpload = ({ onClick, taskCode, canUpload }: Props) => {
  const { t } = useTranslation();
  const isUploadInProgress = useFileUploadStore((state) => isFilesUploading(state.fileStatuses));
  if (isUploadInProgress) {
    return null;
  }
  return (
    <div className={!canUpload ? "cursor-not-allowed" : ""}>
      <Button
        type="button"
        variant="outline"
        onClick={onClick}
        disabled={!canUpload}
        data-test={`btn-upload-file-${taskCode}`}
        data-dd-action-name="btn-upload-file"
        className={"hidden w-full flex-nowrap gap-2 text-nowrap sm:flex"}
      >
        <UploadIcon className="size-5 shrink-0" data-icon="CloudUploadIcon" />
        <span className="">{t("task.upload.title")}</span>
      </Button>
    </div>
  );
};

export default TaskBaseFilesUpload;
