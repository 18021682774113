import { XCircleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface Props {
  error: string | null;
}

function Error({ error }: Props) {
  const { t } = useTranslation();

  return error ? (
    <div className="rounded-md bg-red-50 p-4 dark:bg-red-200/90">
      <div className="flex items-center">
        <div className="shrink-0">
          <XCircleIcon className="size-5 text-red-400 dark:text-red-800" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-red-600 dark:text-red-800"> {t(error)}</p>
        </div>
      </div>
    </div>
  ) : null;
}

export default Error;
