import { Button } from "components/ui/button";
import { DownloadCloudIcon } from "lucide-react";
import { TaskBase } from "pages/project/common/taskBase.typing";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHubConnection } from "store/useHubConnection";
import { LanguageType } from "utils/language";

interface Props<TaskBaseType extends TaskBase> {
  selectedTaskBase: TaskBaseType;
  disableDownload: boolean;
  onRequestZipFullDownload: (
    selectedTaskBase: TaskBaseType,
    currentLanguage: LanguageType,
    hubConnectionId: string
  ) => unknown;
}

function TaskBaseFilesDownload<TaskBaseType extends TaskBase>({
  selectedTaskBase,
  ...props
}: Props<TaskBaseType>): ReactElement {
  const { hub } = useHubConnection((state) => ({ hub: state.connection }));
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as LanguageType;

  const handleOnDownload = () => {
    if (selectedTaskBase && hub?.connectionId) {
      props.onRequestZipFullDownload(selectedTaskBase, currentLanguage, hub.connectionId);
    }
  };

  return (
    <Button
      variant="outline"
      disabled={props.disableDownload}
      data-test={`btn-download-file-${selectedTaskBase.id}`}
      data-dd-action-name="btn-download-file"
      className="flex w-full flex-nowrap gap-2 text-nowrap"
      onClick={handleOnDownload}
    >
      <DownloadCloudIcon className="size-5 shrink-0" data-icon="CloudDownloadIcon" />
      <span>{t("translation:fileDownload.link")}</span>
    </Button>
  );
}

export default TaskBaseFilesDownload;
