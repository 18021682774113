import Loader from "components/Loader";
import { useMemsourceProgressData } from "query/task.query";
import { CatToolSegments } from "../../../common/taskBase/list/CatToolSegments";

interface Props {
  taskId: string;
  isMemSourceCatTool: boolean;
}

export function MemSourceSideBar({ taskId, isMemSourceCatTool }: Props) {
  const { steps, isFetching: memSourceIsFetching } = useMemsourceProgressData(taskId, true, isMemSourceCatTool);

  if (!isMemSourceCatTool) {
    return null;
  }

  if (memSourceIsFetching) {
    return <Loader isShown={true} data-test="memsource-segments-loader" />;
  }

  return steps ? <CatToolSegments steps={steps} tool="memsource" data-test="memsource-segments" /> : null;
}
