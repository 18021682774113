import Listbox from "components/listbox/ListBox";
import { LoaderIcon } from "react-hot-toast";
import { IdName } from "../../api/api.typing";
import { useSupplierContactList } from "../../query/task.query";

interface Props {
  type?: "filter" | "formInput";
  supplierContactId: number;
  onSupplierContactChange: (supplierContactId: number, supplierContactName: string) => void;
}

export default function SupplierContactListBox({ supplierContactId, onSupplierContactChange }: Props) {
  const { supplierContacts, isFetching } = useSupplierContactList();
  return (
    <div>
      {isFetching && <LoaderIcon className={"size-5"} />}
      {!isFetching && (
        <Listbox<IdName>
          placeholder="Select a Supplier contact"
          selectedId={supplierContactId}
          onSelect={(selected) => {
            if (selected?.id) onSupplierContactChange(selected.id, selected.name);
          }}
          items={supplierContacts}
          className="mt-1 w-full"
        />
      )}
    </div>
  );
}
