import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { USER_TYPES, UserTypeCode } from "api/api.typing";
import Avatar from "components/Avatar";
import PartnerPortalLogo from "components/svgs/Logo";
import { OFFER_LIST_TYPE } from "pages/project/offer/offer.typing";
import { REQUEST_LIST_TYPE } from "pages/project/request/request.typing";
import UploadPopover from "pages/project/task/components/UploadDialog/UploadPopover";
import { TASK_LIST_TYPE } from "pages/project/task/task.typing";
import { useLogoutMutation, useUser } from "query/user.query";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { formatUserName } from "utils/format";
import { cn } from "utils/ui";

interface NavigationLink {
  name: string;
  href: string;
  isActivePattern: string;
  forUserTypes: UserTypeCode[];
}

const navigation: NavigationLink[] = [
  {
    name: "navbar.offers",
    href: `/project/offer/list/${OFFER_LIST_TYPE.pending}`,
    isActivePattern: "/project/offer/list",
    forUserTypes: [USER_TYPES.supplier],
  },
  {
    name: "navbar.requests",
    href: `/project/request/list/${REQUEST_LIST_TYPE.pending}`,
    isActivePattern: "/project/request/list",
    forUserTypes: [USER_TYPES.customer],
  },
  {
    name: "navbar.tasks",
    href: `/project/task/list/${TASK_LIST_TYPE.upcoming}`,
    isActivePattern: "/project/task/list",
    forUserTypes: [USER_TYPES.supplier, USER_TYPES.customer],
  },
  {
    name: "navbar.pre-invoice",
    href: `/pre-invoice`,
    isActivePattern: "/pre-invoice",
    forUserTypes: [USER_TYPES.supplier],
  },
];

function PageNavBar() {
  const location = useLocation();
  const { data, isLoading } = useUser();
  const { t } = useTranslation("translation");
  const { mutate: doLogout } = useLogoutMutation();

  const tabsToDisplay = data && !isLoading ? navigation.filter((n) => n.forUserTypes.includes(data.userType)) : [];

  return (
    <Disclosure as="nav" className="shadow-sm">
      {({ open }) => (
        <>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex w-64 shrink-0 flex-col items-start justify-center">
                  <PartnerPortalLogo size="normal" />
                </div>
                {/* display > sm*/}
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  {tabsToDisplay.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) =>
                        cn(
                          isActive || location.pathname.indexOf(item.isActivePattern) >= 0
                            ? "border-ribbon-500 font-semibold text-ribbon-500 dark:text-ribbon-400"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-slate-300 dark:hover:text-slate-50",
                          "mt-2 inline-flex items-center border-b-2 px-2 pb-2 text-base font-medium"
                        )
                      }
                    >
                      {t(item.name)}
                    </NavLink>
                  ))}
                </div>
              </div>
              {/* notif-bell */}
              <div className="hidden space-x-4 sm:ml-6 sm:flex sm:items-center">
                <UploadPopover />
                <button
                  type="button"
                  className="rounded-full bg-transparent p-2.5 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-4 focus:ring-slate-200 dark:text-slate-400 dark:hover:bg-slate-700 dark:focus:ring-slate-700"
                  data-test="button-notification"
                >
                  <span className="sr-only">{t("navbar.viewNotifications")}</span>
                  <BellIcon className="size-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <div>
                    <Menu.Button
                      className="flex rounded-full bg-transparent text-sm focus:outline-none focus:ring-4 focus:ring-slate-200 dark:focus:ring-slate-700"
                      data-test="button-open-user-menu"
                    >
                      <span className="sr-only">{t("navbar.openUserMenu")}</span>
                      {data && <Avatar firstName={data.firstName} lastName={data.lastName} />}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none dark:bg-slate-800">
                      {data && (
                        <div className="px-4 py-3">
                          <p className="text-color-secondary text-sm" data-test="signed-in-as-label">
                            {t("navbar.signedInAs")}
                          </p>
                          <p
                            className="text-color-primary truncate text-sm font-medium"
                            data-test="signed-in-as-full-name"
                            title={formatUserName(data.firstName, data.lastName)}
                          >
                            {formatUserName(data.firstName, data.lastName)}
                          </p>
                          <p className="truncate text-sm" title={data.email} data-test="signed-in-as-email">
                            {data.email}
                          </p>
                        </div>
                      )}
                      {/*Disabled by TPO-4037, reactive once the profile page is ready */}
                      {/*<Menu.Item>

                           <NavLink to="/not-found" className="block px-4 py-2 text-base dark:hover:bg-slate-700">
                          {t("navbar.yourProfile")}
                        </NavLink>
                      </Menu.Item>*/}
                      <Menu.Item>
                        <button
                          type="button"
                          onClick={() => doLogout()}
                          className="block w-full px-4 py-2 text-left text-base dark:hover:bg-slate-700"
                          data-test="button-sign-out"
                        >
                          {t("navbar.signOut")}
                        </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button
                  className={cn(
                    "offset-ring-500 inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 " +
                      "hover:bg-slate-100 hover:text-gray-500 dark:bg-slate-700 dark:text-slate-300"
                  )}
                >
                  <span className="sr-only">{t("navbar.openMainMenu")}</span>
                  {open ? (
                    <XMarkIcon className="block size-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block size-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          {/*display for < sm*/}
          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {tabsToDisplay.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  end={true}
                  className={({ isActive }) =>
                    cn(
                      isActive
                        ? "border-ribbon-500 bg-ribbon-100 text-ribbon-700 dark:bg-gray-900 dark:text-slate-50"
                        : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800 dark:text-slate-300 dark:hover:bg-slate-700 hover:dark:text-slate-50",
                      "mx-2 block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                    )
                  }
                >
                  {t(item.name)}
                </NavLink>
              ))}
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4 dark:border-gray-500">
              <div className="flex items-center px-4">
                <div className="shrink-0">{data && <Avatar firstName={data.firstName} lastName={data.lastName} />}</div>
                {data && (
                  <div className="ml-3 flex-1">
                    <div className="text-base font-medium text-gray-800 dark:text-slate-50">
                      {data.firstName} {data.lastName}
                    </div>
                    <div className="text-sm font-medium text-gray-500 dark:text-slate-50">{data.email}</div>
                  </div>
                )}
                <div className="flex items-center space-x-4">
                  <button
                    type="button"
                    className="ml-auto shrink-0 rounded-full bg-transparent p-2.5 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-4 focus:ring-slate-200 dark:text-slate-400 dark:hover:bg-slate-700 dark:focus:ring-slate-700"
                  >
                    <span className="sr-only">{t("navbar.viewNotifications")}</span>
                    <BellIcon className="size-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                {/*Disabled by TPO-4037, reactive once the profile page is ready */}
                {/* <NavLink
                  to="/not-found"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 dark:bg-slate-800 dark:text-gray-50 dark:hover:bg-slate-700"
                >
                  {t("navbar.yourProfile")}
                </NavLink>*/}
                <button
                  type="button"
                  onClick={() => {
                    doLogout();
                  }}
                  className="w-full px-4 py-2 text-left text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 dark:bg-slate-800 dark:text-gray-50 dark:hover:bg-slate-700"
                >
                  {t("navbar.signOut")}
                </button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default PageNavBar;
