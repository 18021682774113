import * as ProgressPrimitive from "@radix-ui/react-progress";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { cn } from "utils/ui";

type Props = ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & { classNameIndicator?: string };

const Progress = forwardRef<ElementRef<typeof ProgressPrimitive.Root>, Props>(
  ({ className, classNameIndicator, value, ...props }, ref) => (
    <ProgressPrimitive.Root
      ref={ref}
      className={cn("relative h-4 w-full overflow-hidden rounded-full bg-slate-100 dark:bg-slate-800", className)}
      {...props}
    >
      <ProgressPrimitive.Indicator
        className={cn("size-full flex-1 bg-ribbon-500 transition-all dark:bg-ribbon-100", classNameIndicator)}
        style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
      />
    </ProgressPrimitive.Root>
  )
);
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
