import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

interface Props {
  isUploadInProgress: boolean;
  isCanCancelAll: boolean;
  reset: () => void;
  cancelAll: () => void;
}

export const UploadPopoverMenu = ({ cancelAll, isUploadInProgress, isCanCancelAll, reset }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {!isUploadInProgress ? (
        <button
          type="button"
          onClick={reset}
          data-test="btn-upload-dialog-close"
          className="rounded-full bg-transparent p-2.5 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-4 focus:ring-slate-200 dark:text-slate-400 dark:hover:bg-slate-600 dark:focus:ring-slate-600"
        >
          <XMarkIcon className="size-6" aria-hidden="true" data-icon="XIcon" />
        </button>
      ) : null}
      {isCanCancelAll ? (
        <Menu as="div" className="relative inline-block text-left" data-test="upload-dialog-menu">
          <div>
            <Menu.Button
              type="button"
              data-test="btn-upload-dialog-openMenu"
              className="rounded-full bg-transparent p-2.5 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-4 focus:ring-slate-200 dark:text-slate-400 dark:hover:bg-slate-600 dark:focus:ring-slate-600"
            >
              <EllipsisVerticalIcon className="size-6" aria-hidden="true" data-icon="DotsVerticalIcon" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              data-test="upload-dialog-menu-items"
              className="absolute right-0 top-10 mt-2 w-40 origin-top-right rounded-md bg-white p-2 shadow-lg ring-1 ring-black/5 dark:bg-slate-600"
            >
              <Menu.Item as={Fragment}>
                <button
                  data-test="btn-upload-dialog-cancelAll"
                  onClick={cancelAll}
                  className={cn(
                    "w-full rounded-lg p-2 text-left text-base font-medium transition duration-150 ease-in-out hover:bg-slate-100 dark:hover:bg-slate-500",
                    "focus:outline-none focus:ring-2 focus:ring-slate-200 dark:focus:outline-slate-500 dark:focus:ring-slate-500"
                  )}
                >
                  {t("task.upload.popover.btn-cancel-all")}
                </button>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      ) : null}
    </>
  );
};
