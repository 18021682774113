import Loader from "components/Loader";
import { useXtmData } from "query/task.query";
import { CatToolSegments } from "../../../common/taskBase/list/CatToolSegments";

interface Props {
  taskId: string;
  isXtmCatTool: boolean;
}

export function XtmsideBar({ taskId, isXtmCatTool }: Props) {
  const { steps, isFetching: xtmIsFetching } = useXtmData(taskId, true, isXtmCatTool);

  if (!isXtmCatTool) {
    return null;
  }

  if (xtmIsFetching) {
    return <Loader isShown={true} data-test="xtm-segments-loader" />;
  }

  return steps ? <CatToolSegments steps={steps} tool={"xtm"} data-test="xtm-segments" /> : null;
}
