interface Props {
  firstName: string;
  lastName: string;
}

const Avatar = ({ firstName, lastName }: Props) => {
  const firstNameValue = (firstName ?? "").trim();
  const lastNameValue = (lastName ?? "").trim();
  if (firstNameValue.length === 0 && lastNameValue.length === 0) {
    return null;
  }
  return (
    <div className="relative flex size-10 items-center justify-center rounded-full bg-ribbon-500 text-sm font-medium uppercase text-white">
      {firstNameValue[0] ?? ""}
      {lastNameValue[0] ?? ""}
    </div>
  );
};

export default Avatar;
