import { Dialog } from "@headlessui/react";
import { CloudArrowUpIcon, TrashIcon } from "@heroicons/react/24/outline";
import Button from "components/buttons/Button";
import SwitchButton from "components/buttons/SwitchButton";
import Modal from "components/modal/Modal";
import { toastContent } from "components/toastContent";
import { t } from "i18next";
import type { TaskFileUploadType } from "pages/project/common/taskBase.typing";
import { validateFile } from "pages/project/task/components/modals/fileUploadValidation";
import { useManagedFilesToBeUploaded } from "pages/project/task/components/modals/useManagedFilesToBeUploaded";
import { useDeliverTaskMutation } from "query/task.query";
import { useState } from "react";
import type { FileRejection } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";
import { Trans } from "react-i18next";
import { useFileUploadStore } from "store/useFileUploadStore";
import { bytesToSize } from "utils/format";

interface Props {
  taskId: string;
  catToolId: number;
  taskCode: string;
  onClose: () => void;
  canDeliver: boolean;
  isUserSupplier: boolean;
  fileType: TaskFileUploadType;
  usePartnerPortalUploadWithAntivirus: boolean;
}

const MAX_FILE_SIZE = 2_000_000_000; // 2 GB

const onFileDropRejected = (rejected: FileRejection[]) => {
  for (const file of rejected) {
    const error = t(`task.upload.errors.${file.errors[0].code}`);
    const errorDetail = file.errors[0].message;
    toast.error(toastContent(t("task.upload.toaster-title"), `${file.file.name}\r\n${errorDetail ?? error}`));
  }
};

const UploadTaskMaterialModal = ({
  taskId,
  taskCode,
  onClose,
  canDeliver,
  catToolId,
  isUserSupplier,
  fileType,
  usePartnerPortalUploadWithAntivirus,
}: Props) => {
  const [isTaskDelivered, toggleTaskDelivered] = useState<boolean>(false);
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDropRejected: onFileDropRejected,
    validator: validateFile,
    multiple: true,
    maxSize: MAX_FILE_SIZE,
  });

  const title = t(fileType === "delivery" ? "task.upload.title" : "task.upload-notes.title");

  const { deliverTask } = useDeliverTaskMutation(taskId, catToolId, isUserSupplier);
  const upload = useFileUploadStore((state) => state.upload);

  const { files, removeFile } = useManagedFilesToBeUploaded(acceptedFiles);

  const handleCancel = () => {
    if (files.length > 0) {
      toast(t("task.upload.upload-canceled"));
    }
    onClose();
  };

  const handleUpload = () => {
    onClose();
    upload(taskId, taskCode, isTaskDelivered, files, fileType, deliverTask, usePartnerPortalUploadWithAntivirus);
  };

  return (
    <Modal isShown={true}>
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title as="h3" className="text-color-primary text-lg font-medium leading-6 sm:text-3xl">
          <div>{title}</div>
          <div>{taskCode}</div>
        </Dialog.Title>
      </div>

      <div className="mx-4 mt-5">
        <div className="flex w-full items-center justify-center">
          <div
            className="flex h-56 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-800"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              <CloudArrowUpIcon className="size-10 text-gray-500 dark:text-gray-400" />
              {isDragActive ? (
                <p className="text-color-secondary mb-2 text-sm">{t("task.upload.upload-message-active") as string}</p>
              ) : (
                <>
                  <p className="text-color-secondary mb-2 text-sm">
                    <Trans
                      i18nKey="task.upload.upload-message"
                      components={{
                        s: <span className="font-semibold" />,
                      }}
                    />
                  </p>
                  <p className="text-color-secondary text-xs">ZIP, PDF, DOC,... (MAX. {bytesToSize(MAX_FILE_SIZE)})</p>
                </>
              )}
            </div>
          </div>
        </div>

        {files.length > 0 && (
          <div className="mt-5 space-y-2">
            <h5 className="font-medium">
              {files.length} {t("task.upload.selected-files") as string}
            </h5>
            <div className="max-h-40 overflow-y-auto">
              <ul>
                {files.map((acceptedFile) => (
                  <li key={acceptedFile.name}>
                    <div className="flex items-baseline justify-between space-x-5">
                      <div className="flex items-center space-x-1 truncate">
                        <button type="button" className="" onClick={() => removeFile(acceptedFile)}>
                          <TrashIcon className="size-4 text-red-400" />
                        </button>
                        <span className="truncate font-medium" title={acceptedFile.name}>
                          {acceptedFile.name}
                        </span>
                      </div>
                      <span className="min-w-fit">{bytesToSize(acceptedFile.size)}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {canDeliver && (
          <div className="mt-5 border-t border-slate-200 pt-5 dark:border-slate-700">
            <SwitchButton
              checked={isTaskDelivered}
              toggleCheck={toggleTaskDelivered}
              label={t(isUserSupplier ? "task.upload.mark-delivered" : "task.upload.mark-validated")}
            />
          </div>
        )}
        <div className="mt-5 grid grid-cols-2 gap-3 sm:mt-6 sm:grid-flow-row-dense">
          <Button type="button" color="ternary" label={t("task.upload.btn-cancel")} onClick={() => handleCancel()} />
          <Button
            type="button"
            color="primary"
            label={t("task.upload.btn-upload")}
            disabled={files.length === 0}
            onClick={handleUpload}
          />
        </div>
      </div>
    </Modal>
  );
};
export default UploadTaskMaterialModal;
