import { Listbox, Transition } from "@headlessui/react";
import { ArrowsUpDownIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { cn } from "utils/ui";

export type Tab<TabType extends string> = { name: string; to: TabType; buildUrl: () => string; count: number };

interface Props<TabType extends string> {
  selectedTab: TabType | null;
  tabs: Tab<TabType>[];
  dataTestId: string;
  pageName: string;
  title: string;
  subTitle: string;
}

/*
Design remark:
The state is changed here but read by the parent without notification using a props.
Indeed, you can see useNavigate as [_,setState] here and useParams in the parent as [state, _].
This is not ideal as this component can have side effect on the parent without it being clear.
That said there is no perfect solution.
- What could be done is replacing NavLink by button (+ onClick + props:onTabClick)
but then it breaks the convention that navigation should happen through links.
- you could also use useEffect and props:onTabChange to notify the parent but then you would have "a double state"
- you could also render tabs by passing a function to the this component but it brings more complexity and breaks the main
responsibility of this component

So we decided to stay on this solution but to make the "navigation" side effect a bit more obvious
we rename the component to NavigationTabsHeader
*/

function NavigationTabsHeader<TabType extends string>({ tabs, dataTestId, ...props }: Props<TabType>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selected = tabs.find((tab) => tab.to === props.selectedTab) ?? tabs[0];

  return (
    <div
      className="text-color-primary bg-color-base border-b border-slate-200 dark:border-slate-600"
      data-test={dataTestId}
    >
      <div className="px-10 py-6">
        <h1 className="text-3xl font-medium leading-tight" data-test={`${dataTestId}-title`}>
          <span>{props.title}</span>
        </h1>
        <p className="text-color-secondary text-sm">{props.subTitle}</p>
      </div>

      <div className="mx-10 hidden sm:flex sm:items-baseline">
        <nav className="flex space-x-8" data-test={`${dataTestId}-navigation-list`}>
          {tabs.map((tab) => (
            <NavLink
              key={tab.to}
              data-test={`anchor-${dataTestId}-navigate-${tab.to}`}
              data-dd-action-name={
                `navigate-to-${tab.to}-${props.pageName}` /* same here I feel like this does not work */
              }
              role="link"
              to={tab.buildUrl()}
              className={({ isActive }) =>
                cn(
                  isActive
                    ? "border-ribbon-500 font-semibold text-ribbon-500 dark:text-ribbon-400"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-slate-300 dark:hover:text-slate-50",
                  "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                )
              }
            >
              <span>{t(tab.name)}</span>
              {!!tab.count && tab.count > 0 && (
                <span
                  className={cn(
                    "ml-2 rounded-full px-2 py-0",
                    selected.to === tab.to
                      ? "bg-ribbon-500 text-ribbon-100"
                      : "bg-gray-200 text-gray-600 dark:bg-slate-300 dark:text-slate-700"
                  )}
                >
                  {tab.count}
                </span>
              )}
            </NavLink>
          ))}
        </nav>
      </div>
      <div className="mx-8 mb-5 block sm:hidden">
        <Listbox
          value={selected}
          onChange={({ buildUrl }) => {
            navigate(buildUrl());
          }}
        >
          {({ open }) => (
            <div className="relative">
              <Listbox.Button className="offset-ring-500 relative w-full cursor-default rounded-md border border-gray-300 py-2 pl-3 pr-10 text-left shadow-sm dark:border-slate-600 sm:text-sm">
                <span className="block truncate">{t(selected.name)}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ArrowsUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none dark:bg-slate-700 sm:text-sm">
                  {tabs.map((tab) => (
                    <Listbox.Option
                      key={tab.to}
                      className={
                        "relative cursor-default select-none py-2 pl-4 transition duration-150 ease-in-out hover:bg-slate-100 dark:hover:bg-slate-600"
                      }
                      value={tab}
                    >
                      <span
                        className={cn({
                          "font-medium text-ribbon-500 dark:text-ribbon-400": tab.to === selected.to,
                        })}
                      >
                        {t(tab.name)}
                      </span>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          )}
        </Listbox>
      </div>
    </div>
  );
}

export default NavigationTabsHeader;
