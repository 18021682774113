import Loader from "components/Loader";
import { Button } from "components/ui/button";

import { useMemsourceJobsCompletion, useMemSourceProjectLinkList } from "query/task.query";
import { useTranslation } from "react-i18next";

interface Props {
  taskId: string;
  isTaskMemSourceCatTool: boolean;
  isXtmCatTool: boolean;
  shouldDisplayQAForm: boolean;
  onDeliverClicked: () => void;
  isUserSupplier?: boolean;
  memsourceJobsCompleted?: boolean;
}

export const DeliveryButton = ({
  taskId,
  shouldDisplayQAForm,
  isTaskMemSourceCatTool,
  isXtmCatTool,
  onDeliverClicked,
  isUserSupplier,
}: Props) => {
  const { hasLinks, isFetching: memSourceFetching } = useMemSourceProjectLinkList(taskId, isTaskMemSourceCatTool);
  const { completed: areMemsourceJobsCompleted, isFetching: memSourceJobsCompletionFetching } =
    useMemsourceJobsCompletion(taskId, isTaskMemSourceCatTool, hasLinks, shouldDisplayQAForm, true);

  if (memSourceFetching || memSourceJobsCompletionFetching) {
    return <Loader isShown={true} />;
  }

  //Xtm
  if (isXtmCatTool) {
    // not all segments are done
    return <InnerDeliverButton onClick={onDeliverClicked} isUserSupplier={isUserSupplier} />;
  }

  // not memsource
  if (!isTaskMemSourceCatTool) {
    return <InnerDeliverButton onClick={onDeliverClicked} isUserSupplier={isUserSupplier} />;
  }

  // memsource with should display QA form
  if (shouldDisplayQAForm) {
    return (
      <InnerDeliverButton
        onClick={onDeliverClicked}
        isUserSupplier={isUserSupplier}
        isDisabled={isTaskMemSourceCatTool && shouldDisplayQAForm && hasLinks && !areMemsourceJobsCompleted}
      />
    );
  }

  // memsource without QA form and without links
  if (!hasLinks) {
    return <InnerDeliverButton onClick={onDeliverClicked} isUserSupplier={isUserSupplier} />;
  }

  return null;
};

function InnerDeliverButton(props: { onClick: () => void; isUserSupplier?: boolean; isDisabled?: boolean }) {
  const { t } = useTranslation();
  return (
    <div data-test="delivery-container" className="w-full">
      <Button
        className="w-full bg-ribbon-500 text-white shadow-sm hover:bg-ribbon-400 hover:text-white dark:bg-ribbon-500 dark:hover:bg-ribbon-400"
        variant={"outline"}
        data-test={"btn-deliver-task"}
        onClick={() => props.onClick()}
        disabled={props.isDisabled}
      >
        {t(props.isUserSupplier ? "task.detail.deliver" : "task.detail.validate")}
      </Button>
    </div>
  );
}
