import { Switch } from "@headlessui/react";
import { cn } from "utils/ui";

interface Props {
  label: string;
  checked: boolean;
  toggleCheck: (enabled: boolean) => void;
  disabled?: boolean;
}

const SwitchButton = ({ label, checked, toggleCheck, disabled = false }: Props) => {
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={checked}
        disabled={disabled}
        onChange={toggleCheck}
        className={cn(
          disabled ? "bg-gray-200  dark:bg-slate-700" : "cursor-pointer",
          !disabled && checked ? "bg-ribbon-500" : "bg-gray-200 dark:bg-gray-700",
          "relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-ribbon-400"
        )}
      >
        <span
          aria-hidden="true"
          className={cn(
            checked ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span
          className={cn(
            disabled ? "text-gray-400 dark:text-slate-500" : "text-color-secondary cursor-pointer font-medium"
          )}
        >
          {label}
        </span>
      </Switch.Label>
    </Switch.Group>
  );
};

export default SwitchButton;
