import { FC, useEffect } from "react";
import { useHubConnection } from "store/useHubConnection";
import { useOfferFileStore } from "store/useOfferFileStore";
import { useTaskFileStore } from "store/useTaskFileStore";
import { useValidationRequestFileStore } from "store/useRequestFileStore";

const FileDownloadListener: FC = () => {
  const { hub } = useHubConnection((state) => ({ hub: state.connection }));
  const { ready: offerZipReady } = useOfferFileStore((state) => ({ ready: state.onReadyToDownload }));
  const { ready: taskZipReady } = useTaskFileStore((state) => ({ ready: state.onReadyToDownload }));
  const { ready: validationExecutionRequestZipReady } = useValidationRequestFileStore((state) => ({
    ready: state.onReadyToDownload,
  }));

  useEffect(() => {
    if (hub) {
      hub.off("PartnerOfferFullDownloadReady");

      // ready will be called when the web socket receives a message targeting OfferFullDownloadReady
      hub.on("PartnerOfferFullDownloadReady", offerZipReady);
    }
  }, [hub, offerZipReady]);

  useEffect(() => {
    if (hub) {
      hub.off("PartnerTaskFullDownloadReady");

      // ready will be called when the web socket receives a message targeting TaskFullDownloadReady
      hub.on("PartnerTaskFullDownloadReady", taskZipReady);
    }
  }, [hub, taskZipReady]);

  useEffect(() => {
    if (hub) {
      hub.off("PartnerValidationExecutionRequestFullDownloadReady");

      // ready will be called when the web socket receives a message targeting ValidationExecutionRequestFullDownloadReady
      hub.on("PartnerValidationExecutionRequestFullDownloadReady", validationExecutionRequestZipReady);
    }
  }, [hub, validationExecutionRequestZipReady]);

  return <></>;
};

export default FileDownloadListener;
