import { Disclosure } from "@headlessui/react";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { FC, PropsWithChildren, ReactElement } from "react";
import { LoaderIcon } from "react-hot-toast";
import { cn } from "utils/ui";

interface Props {
  header: string | ReactElement;
  isLoading?: boolean;
  defaultOpen?: boolean;
}

const Collapse: FC<PropsWithChildren<Props>> = ({
  children,
  header,
  isLoading = false,
  defaultOpen = false,
}: PropsWithChildren<Props>) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button
            as="div"
            data-test="btn-collapse"
            className={cn({ "cursor-pointer": !isLoading }, "text-color-primary")}
          >
            <div className="flex flex-row space-x-4">
              <div className="flex flex-col justify-around">
                {isLoading ? (
                  <LoaderIcon className="size-5" data-icon="LoaderIcon" />
                ) : (
                  <ChevronRightIcon data-icon="ChevronRightIcon" className={cn({ "rotate-90": open }, "size-5")} />
                )}
              </div>
              <div>{header}</div>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel>{children}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Collapse;
