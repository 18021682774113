import { enUS, fr } from "date-fns/locale";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { getLocalStorageValue, setLocalStorageValue } from "utils/storage";

// noinspection AllyPlainJsInspection
export const SUPPORTED_LANGUAGES = {
  en: { name: "English", tempoCode: "ENG", locale: enUS },
  fr: { name: "Francais", tempoCode: "FRA", locale: fr },
};

export const SUPPORTED_LANGUAGES_KEYS = Object.keys(SUPPORTED_LANGUAGES);
export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES_KEYS[0] as LanguageType;

export type LanguageType = keyof typeof SUPPORTED_LANGUAGES;

export const useCurrentLanguage = () => {
  const { i18n } = useTranslation();
  const language = i18n.language as LanguageType;

  return { language, locale: SUPPORTED_LANGUAGES[language].locale };
};

export const detectLanguage = () => {
  let detectedLanguage = getLocalStorageValue<string>("pp-language-key", "Read language");
  if (!detectedLanguage) {
    const languages = navigator.languages.map((lang) => lang.split("-")[0]);
    detectedLanguage = languages.find((lang) => SUPPORTED_LANGUAGES_KEYS.includes(lang)) ?? null;
  }
  const language = (detectedLanguage as LanguageType) ?? DEFAULT_LANGUAGE;
  onLanguageChange(language); // synchronize with document and local storage
  return language;
};

export const updateLanguage = (language: LanguageType) => {
  changeLanguage(language).then();
  onLanguageChange(language);
};

const onLanguageChange = (language: LanguageType) => {
  setLocalStorageValue("pp-language-key", language, "Set language");
  updateDocumentLang(language);
};

const updateDocumentLang = (language: LanguageType) => {
  try {
    if (document.documentElement.getAttribute("lang") !== language) {
      document.documentElement.setAttribute("lang", language);
    }
  } catch (ignore) {
    // ignore exception
  }
};
