import {
  FileDownloadUrlDtoResponse,
  OfferDtoResponse,
  RejectionReasonDto,
  ZipFullDownloadDtoResponse,
} from "api/api.typing";
import api from "api/api";
import { LanguageType, SUPPORTED_LANGUAGES } from "utils/language";
import { requestDownloadFile } from "./utils";

export const fetchOffers = async (language: LanguageType): Promise<OfferDtoResponse> => {
  const response = await api.get<OfferDtoResponse>(
    `/api/offers?translationLanguageCode=${SUPPORTED_LANGUAGES[language].tempoCode}`
  );
  return response.data;
};

export const fetchRejectReasons = async (language: LanguageType): Promise<RejectionReasonDto[]> => {
  const response = await api.get(
    `/api/MarketEmailRejectionReasons?translationLanguageCode=${SUPPORTED_LANGUAGES[language].tempoCode}`
  );
  return response.data.marketEmailRejectionReasons;
};

export const requestRejectOffer = async (offerId: string, reasonId: string, comment?: string): Promise<void> => {
  await api.put<void>(`/api/offers/${offerId}/reject`, {
    marketEmailSupplierId: offerId,
    marketEmailRejectionReasonId: reasonId,
    supplierDecisionComment: comment,
  });
};

export const requestAcceptOffer = async (offerId: string, comment?: string): Promise<void> => {
  await api.put<void>(`/api/offers/${offerId}/accept`, {
    marketEmailSupplierId: offerId,
    supplierDecisionComment: comment,
  });
};

export const requestZipFullOfferDownload = async (
  offerId: string,
  language: LanguageType,
  hubConnectionId: string
): Promise<ZipFullDownloadDtoResponse> => {
  const response = await api.post(`/api/offers/${offerId}/zipFullDownload`, {
    offerId,
    languageCode: SUPPORTED_LANGUAGES[language].tempoCode,
    hubConnectionId,
  });

  return response.data;
};

export const requestDownloadFullOfferZip = async (offerId: string, guid: string) => {
  const response = await api.get<FileDownloadUrlDtoResponse>(`/api/offers/${offerId}/fullDownloadUrl?guid=${guid}`);
  await requestDownloadFile(response.data.downloadUrl, { name: "file", extension: "zip" });
};

export const getOfferId = async (tempKey: string): Promise<number | null> => {
  const marketEmailSupplier = 7;
  return await api
    .get(`/api/AnonymousKeyStores/getByKey?anonymousObject=${marketEmailSupplier}&relatorKey=${tempKey}`) //7 => MarketEmailSupplier
    .then((response) => response.data.relatedId)
    .catch(() => null);
};
