import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  noop,
  RowData,
  SortingState,
  Table as TanstackTable,
  useReactTable,
} from "@tanstack/react-table";
import SearchInput from "components/input/SearchInput";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/ui/table";
import { useDataTableColumVisibility } from "hooks/useDataTableColumVisibility";
import { DOTS, usePagination } from "hooks/usePagination";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronDown,
  ChevronLeftIcon,
  ChevronRightIcon,
  Settings2,
  XIcon,
} from "lucide-react";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

interface Props<TData extends RowData> {
  uniqueName: string;
  data: TData[];
  isWithSelectColumn?: boolean;
  searchTerm?: string | null;
  onSearch?: (term: string) => void;
  sortBy: SortingState;
  onSortBy: (sort: SortingState) => void;
  columns: Array<ColumnDef<TData>>;
  onRowClick?: (row: TData) => void;
  defaultPageSize?: number;
  facetedFilters?: (table: TanstackTable<TData>) => ReactElement;
}

export function DataTable<TData extends RowData>({
  uniqueName,
  data,
  columns,
  searchTerm = null,
  sortBy = [],
  onSortBy,
  onSearch,
  isWithSelectColumn = false,
  defaultPageSize = 10,
  onRowClick,
  facetedFilters,
}: Props<TData>) {
  const { t } = useTranslation("translation");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const { columnVisibility, onUpdateColumnVisibility } = useDataTableColumVisibility(uniqueName);
  const [rowSelection, setRowSelection] = useState({});
  const fuzzyFilter = useCallback<FilterFn<TData>>((row, columnId, value) => {
    return String(row.getValue(columnId)).toLowerCase().includes(String(value).toLowerCase());
  }, []);

  const allColumn = useMemo(() => {
    if (isWithSelectColumn) {
      const selectColumn: ColumnDef<TData> = {
        id: "select",
        enableGlobalFilter: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableHiding: false,
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllPageRowsSelected()}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label={t("data-table.selection.selectAll")}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label={t("data-table.selection.selectRow")}
          />
        ),
      };
      return [selectColumn, ...columns];
    }
    return columns;
  }, [columns, isWithSelectColumn, t]);

  const table = useReactTable<TData>({
    data,
    columns: allColumn,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: (onSortingChange) => {
      const newValue = functionalUpdate(onSortingChange, sortBy);
      onSortBy(newValue);
    },
    onColumnVisibilityChange: onUpdateColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: onSearch,
    globalFilterFn: fuzzyFilter,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    initialState: {
      pagination: { pageSize: defaultPageSize },
    },
    state: {
      sorting: sortBy,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter: searchTerm,
    },
  });

  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="w-full">
      <div className="bg-color-base mt-4 flex items-end justify-between gap-2 rounded-t p-4 dark:bg-slate-900">
        <div className="flex flex-1 flex-wrap items-center gap-2">
          <div className="flex max-w-sm flex-1">
            <SearchInput className="min-w-[250px]" term={searchTerm} onSearch={(value) => onSearch?.(value ?? "")} />
          </div>
          {facetedFilters?.(table)}
          {isFiltered && (
            <Button variant="ghost" onClick={() => table.resetColumnFilters()} className="h-8 px-2 lg:px-3">
              {t("translation:data-table.rest-filters")}
              <XIcon className="ml-2 size-4" />
            </Button>
          )}
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto dark:hover:bg-slate-900">
              <Settings2 className="mr-2 size-4" />
              {t("data-table.view-columns")} <ChevronDown className="ml-2 size-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onSelect={(event) => event.preventDefault()} // prevent closing the dropdown
                    onCheckedChange={(value) => column.toggleVisibility(value)}
                  >
                    {typeof column.columnDef.header === "string" ? column.columnDef.header : column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-b-md border border-slate-200 dark:border-slate-800">
        <Table className="overflow-x-auto">
          <TableHeader className="bg-gray-100 dark:bg-slate-900">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="dark:border-slate-600">
                {headerGroup.headers.map((header) => {
                  return <TableSortableHeader key={header.id} header={header} />;
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="w-full min-w-full flex-1 border-slate-200 bg-white dark:border-slate-600 dark:bg-slate-900">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  className="dark:border-slate-600"
                  onClick={() => onRowClick?.(row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {t("data-table.no-result")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        totalCount={table.getFilteredRowModel().rows.length}
        pageSize={table.getState().pagination.pageSize}
        currentPage={table.getState().pagination.pageIndex + 1}
        onPaginationChange={(pagination) => {
          table.setPageSize(pagination.pageSize);
          table.setPageIndex(pagination.pageIndex - 1);
        }}
      />
    </div>
  );
}

function TableSortableHeader<TData>({ header }: { header: Header<TData, unknown> }) {
  const isCanSort = header.column.getCanSort();
  const sortDirection = header.column.getIsSorted();
  return (
    <TableHead key={header.id}>
      {header.isPlaceholder ? null : (
        <Button
          variant="ghost"
          className="pl-0 font-semibold hover:dark:bg-transparent"
          onClick={() => (isCanSort ? header.column.toggleSorting(sortDirection === "asc") : noop())}
        >
          <div className="truncate">{flexRender(header.column.columnDef.header, header.getContext())}</div>
          {sortDirection ? (
            <>
              {sortDirection === "asc" && <ArrowUpIcon className="ml-2 size-4 shrink-0" />}
              {sortDirection === "desc" && <ArrowDownIcon className="ml-2 size-4 shrink-0" />}
            </>
          ) : null}
        </Button>
      )}
    </TableHead>
  );
}

export interface PaginationType {
  pageSize: number;
  pageIndex: number;
}

interface TablePaginationProps {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  onPaginationChange: (pagination: PaginationType) => void;
}

const Page_Sizes = [5, 10, 15, 20];

function TablePagination({ currentPage, totalCount, pageSize, onPaginationChange }: TablePaginationProps) {
  const { t } = useTranslation("translation", { keyPrefix: "data-table.pagination" });
  const paginationRange = usePagination({ currentPage, pageSize, totalCount });

  if (totalCount === 0) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];
  const currentPageCount = currentPage === 1 ? currentPage : (currentPage - 1) * pageSize;
  const totalPageCount = (currentPage - 1) * pageSize + pageSize;

  return (
    <div className="bg-white px-4 py-3 ring-1 ring-black/5 dark:bg-slate-900 sm:px-6 md:rounded-lg md:rounded-t-none">
      <div className="flex flex-1 items-center justify-between">
        <div className="flex items-baseline gap-4">
          <div className="text-color-primary whitespace-nowrap text-sm">
            {totalPageCount < totalCount ? (
              <div className="flex flex-nowrap gap-1">
                <span>{t("showing")}</span>
                <span className="font-medium">{currentPageCount}</span>
                <span>{t("to")}</span>
                <span className="font-medium">{totalPageCount}</span>
                <span>{t("of")}</span>
                <span className="font-medium">{totalCount}</span>
                <span>{t("results")}</span>
              </div>
            ) : (
              <span>
                {t("total")} <span className="font-medium">{totalCount}</span>
              </span>
            )}
          </div>
          <Select
            value={pageSize.toString()}
            onValueChange={(value) => onPaginationChange({ pageSize: Number(value), pageIndex: 1 })}
          >
            <SelectTrigger className="min-w-fit whitespace-nowrap focus:ring-0 focus:ring-offset-0">
              <SelectValue placeholder={t("data-table.pagination.pageSize")} className="mr-4" />
            </SelectTrigger>
            <SelectContent>
              {Page_Sizes.map((pz) => (
                <SelectItem key={pz} value={pz.toString()}>
                  {pz} {t("per-page")}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm">
            <Button
              type="button"
              size="icon"
              variant="outline"
              onClick={() => onPaginationChange({ pageIndex: currentPage - 1, pageSize })}
              disabled={currentPage === 1}
              className="rounded-r-none dark:bg-slate-950 dark:text-slate-400"
            >
              <span className="sr-only">{t("previous")}</span>
              <ChevronLeftIcon className="size-5" aria-hidden="true" />
            </Button>

            {paginationRange.map((pageNumber, idx) => {
              if (pageNumber === DOTS) {
                return (
                  <Button
                    type="button"
                    size="icon"
                    variant="outline"
                    className="rounded-none dark:bg-slate-950 dark:text-slate-400"
                    key={pageNumber + idx}
                  >
                    ...
                  </Button>
                );
              }
              const isActive = (pageNumber as number) === currentPage;
              return (
                <Button
                  key={pageNumber}
                  type="button"
                  size="icon"
                  variant="outline"
                  onClick={() => onPaginationChange({ pageIndex: pageNumber as number, pageSize })}
                  className={cn("rounded-none dark:bg-slate-950 dark:text-slate-400", {
                    "bg-ribbon-500 text-slate-200 hover:bg-ribbon-400 hover:text-slate-200 dark:bg-ribbon-500 dark:text-slate-200 hover:dark:bg-ribbon-400":
                      isActive,
                  })}
                >
                  {pageNumber.toString()}
                </Button>
              );
            })}
            <Button
              type="button"
              size="icon"
              variant="outline"
              disabled={currentPage === lastPage}
              onClick={() => onPaginationChange({ pageIndex: currentPage + 1, pageSize })}
              className="rounded-l-none dark:bg-slate-950 dark:text-slate-400"
            >
              <span className="sr-only">{t("next")}</span>
              <ChevronRightIcon className="size-5" aria-hidden="true" />
            </Button>
          </nav>
        </div>
      </div>
    </div>
  );
}
