import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";
import { clsx } from "clsx";
import { AlertTriangleIcon, Info } from "lucide-react";
import { Progress } from "../../../../../components/ui/progress";

export interface SegmentProgressProps {
  progression: number;
  doneSegments: number;
  totalSegments: number;
  totalDocuments?: number;
}

interface SegmentStatusContainerProps {
  type: "warning" | "info";
  tool: "xtm" | "memsource";
}

export const SegmentStatusContainer = ({ children, type, tool }: PropsWithChildren<SegmentStatusContainerProps>) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn("mb-4 rounded-md p-4", {
        "bg-yellow-100 text-yellow-600 dark:bg-yellow-700 dark:text-yellow-200": type === "warning",
        "bg-ribbon-100 text-ribbon-500 dark:bg-ribbon-700 dark:text-slate-200": type === "info",
      })}
    >
      <div className="flex items-center gap-2">
        <div className={clsx("shrink-0")}>
          {type === "warning" ? (
            <AlertTriangleIcon className="size-8" aria-hidden="true" />
          ) : (
            <Info className="size-8" aria-hidden="true" />
          )}
        </div>
        <div className="ml-3 flex flex-1 flex-col gap-2">
          <p className="text-sm">
            {type === "warning"
              ? t(`translation:task.detail.catSegments.deliver.nok.${tool}`)
              : t(`translation:task.detail.catSegments.deliver.ok.${tool}`)}
          </p>
          {children}
        </div>
      </div>
    </div>
  );
};

export const SegmentProgress = ({ totalDocuments, totalSegments, doneSegments, progression }: SegmentProgressProps) => {
  const { t } = useTranslation();
  const isInProgress = progression < 100;
  return (
    <div className="flex flex-col gap-1">
      <Progress
        value={progression}
        className={cn("hidden sm:block", { "bg-goldfish-300 ": isInProgress })}
        classNameIndicator={isInProgress ? "bg-yellow-500 dark:bg-yellow-500" : "bg-ribbon-500 dark:bg-ribbon-600"}
      />
      <div
        className={cn(
          "text-primary-color flex flex-1 flex-col text-xs sm:flex-row sm:items-center sm:justify-between sm:gap-4"
        )}
      >
        <span>
          {totalDocuments !== undefined ? (
            <span>{t("translation:task.detail.catSegments.document", { totalDocuments })}</span>
          ) : null}
          <span>{t("translation:task.detail.catSegments.segments", { doneSegments, totalSegments })}</span>
        </span>
        <span className="hidden sm:block">{progression} %</span>
        <span className="sm:hidden">{t("translation:task.detail.catSegments.progression", { progression })}</span>
      </div>
    </div>
  );
};
