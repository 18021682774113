import { Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Select from "components/Select";
import Button from "components/buttons/Button";
import TextAreaFormField from "components/form/TextAreaFormField";
import Modal from "components/modal/Modal";
import { Calendar } from "components/ui/calendar";
import { TimePickerInput } from "components/ui/time-picker-input";
import { Clock } from "lucide-react";
import { useRejectValidationRequestForm } from "pages/project/request/components/modals/useRejectValidationRequestForm";
import { RequestListType, ValidationRequestRejectReason } from "pages/project/request/request.typing";
import { useValidationRequest, useValidationRequestRejectionReason } from "query/request.query";
import { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  filterType: RequestListType;
  requestId: string;
}

const RejectValidationRequestModal = ({ show, filterType, requestId }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "request.modal.decline" });

  const [month] = useState(new Date());

  const { isLoading: isRequestLoading, validationRequest } = useValidationRequest(requestId);
  const { data: rejectReasons = [], isLoading } = useValidationRequestRejectionReason();

  const { isRejectReasonShortDeadline, reject, reset, control, register, formErrors } = useRejectValidationRequestForm(
    requestId,
    filterType
  );

  const minuteRef = useRef<HTMLInputElement>(null);
  const hourRef = useRef<HTMLInputElement>(null);
  const secondRef = useRef<HTMLInputElement>(null);

  if (isRequestLoading || !validationRequest) {
    return null;
  }
  return (
    <Modal isShown={show} onClose={reset}>
      <div>
        {/*Icon*/}
        <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-red-100">
          <ExclamationTriangleIcon className="size-6 text-red-600" aria-hidden="true" />
        </div>
        {/*Header*/}
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-color-primary text-lg font-medium leading-6 sm:text-3xl">
            <div>{t("title")}</div>
            <div>{validationRequest.taskCode}</div>
          </Dialog.Title>
          <div className="mt-6 flex flex-col text-center">
            <div className="text-base font-medium">{t("subTitle")}</div>
          </div>
        </div>

        {/*body*/}
        <div className="mx-4 mt-6">
          <form className="mx-4 mt-6 flex flex-col space-y-5" onSubmit={reject}>
            <Controller
              name="reason"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select<ValidationRequestRejectReason>
                  label={t("form.reason")}
                  items={rejectReasons}
                  selectedItem={value}
                  error={formErrors.reason?.id?.message}
                  onSelection={onChange}
                  renderName={(reason) => (reason?.label ? reason.label : t("form.emptyReason"))}
                />
              )}
            />
            {isRejectReasonShortDeadline ? (
              <>
                <Controller
                  name="newDeadline"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <div className="mt-5">
                      <label className="text-color-secondary block font-sans text-sm font-medium">
                        {t("form.newDeadline")}
                      </label>

                      <div className="flex flex-col items-center">
                        <Calendar
                          initialFocus
                          mode="single"
                          fromDate={month}
                          defaultMonth={month}
                          selected={value}
                          onSelect={onChange}
                        />
                        <div className="flex items-end gap-2">
                          <div className="grid gap-1 text-center">
                            <label htmlFor="hours" className="text-color-secondary block font-sans text-xs font-medium">
                              {t("form.hours")}
                            </label>
                            <TimePickerInput
                              picker="hours"
                              date={value}
                              setDate={onChange}
                              ref={hourRef}
                              onRightFocus={() => minuteRef.current?.focus()}
                            />
                          </div>
                          <div className="grid gap-1 text-center">
                            <label
                              htmlFor="minutes"
                              className="text-color-secondary block font-sans text-xs font-medium"
                            >
                              {t("form.minutes")}
                            </label>
                            <TimePickerInput
                              picker="minutes"
                              date={value}
                              setDate={onChange}
                              ref={minuteRef}
                              onLeftFocus={() => hourRef.current?.focus()}
                              onRightFocus={() => secondRef.current?.focus()}
                            />
                          </div>
                          <div className="grid gap-1 text-center">
                            <label
                              htmlFor="seconds"
                              className="text-color-secondary block font-sans text-xs font-medium"
                            >
                              {t("form.seconds")}
                            </label>

                            <TimePickerInput
                              picker="seconds"
                              date={value}
                              setDate={onChange}
                              ref={secondRef}
                              onLeftFocus={() => minuteRef.current?.focus()}
                            />
                          </div>
                          <div className="flex h-10 items-center">
                            <Clock className=" size-4" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </>
            ) : null}
            <TextAreaFormField
              id="comment"
              label={t("form.comment")}
              className="max-h-52"
              {...register("comment")}
              error={formErrors.comment?.message}
            />
            <div className="mt-5 grid grid-cols-2 gap-3 sm:mt-6 sm:grid-flow-row-dense">
              <Button
                type="reset"
                disabled={isLoading}
                color="ternary"
                className="w-full"
                label={t("cancel")}
                onClick={reset}
              />
              <button
                type="submit"
                disabled={isLoading}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 sm:col-start-2"
              >
                {t("confirm")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default RejectValidationRequestModal;
