import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FileUploadStatus, canCancelUpload } from "store/useFileUploadStore";
import { cn } from "utils/ui";

interface Props {
  fileStatuses: FileUploadStatus[];
  onCancel: (file: File) => void;
}

type UploadPopoverFileStatusProps = Pick<Props, "onCancel"> & { id: number; fileStatus: FileUploadStatus };

const UploadPopoverFileStatus: FC<UploadPopoverFileStatusProps> = ({
  id,
  fileStatus,
  onCancel,
}: UploadPopoverFileStatusProps) => {
  const { t } = useTranslation();
  const canBeCanceled = canCancelUpload(fileStatus);
  return (
    <div className="flex flex-col justify-center p-4" data-test={`upload-dialog-file-${id}`}>
      <div className="flex items-center justify-between space-x-2">
        <div
          title={fileStatus.file.name}
          className="truncate font-medium"
          data-test={`upload-dialog-file-${id}-filename`}
        >
          {fileStatus.file.name}
        </div>
        {["failed", "canceled", "waiting"].includes(fileStatus.status) && (
          <div
            data-test={`upload-dialog-file-${id}-status`}
            className={cn(
              "flex items-center space-x-2 text-sm",
              fileStatus.status === "waiting" ? "text-goldfish-500" : "text-red-600 dark:text-red-400/90"
            )}
          >
            {t(`task.upload.status.${fileStatus.status}`)}
          </div>
        )}

        {["uploading", "uploaded"].includes(fileStatus.status) && (
          <div className="flex min-w-fit items-center space-x-2 text-sm text-gray-400">
            <span data-test={`upload-dialog-file-${id}-remaining`}> {fileStatus.remainingTime}</span>
            {!!fileStatus.remainingTime && <span data-test={`upload-dialog-file-${id}-bull`}>&bull;</span>}
            {fileStatus.status === "uploading" ? (
              <span data-test={`upload-dialog-file-${id}-progress`}>{fileStatus.progression}%</span>
            ) : (
              <div
                className="rounded-full bg-green-50 p-1 dark:bg-green-400/50"
                data-test={`upload-dialog-file-${id}-uploaded`}
              >
                <CheckIcon className="size-5 text-green-400" data-icon="CheckIcon" />
              </div>
            )}
            {canBeCanceled && (
              <button
                type="button"
                data-test={`btn-upload-dialog-file-${id}-cancel`}
                className="rounded-full bg-gray-100 p-1 hover:bg-gray-200 dark:bg-slate-600 dark:hover:bg-slate-800"
                onClick={() => onCancel(fileStatus.file)}
              >
                <XMarkIcon
                  className="size-4 font-extrabold text-gray-400 hover:text-gray-700 dark:text-slate-400 hover:dark:text-slate-300"
                  data-icon="XIcon"
                />
              </button>
            )}
          </div>
        )}
      </div>
      {fileStatus.status === "uploading" && (
        <div className="mt-2 h-0.5 w-full rounded-full bg-gray-200" data-test={`upload-dialog-file-${id}-progressbar`}>
          <div
            className="h-0.5 rounded-full bg-ribbon-300"
            data-test={`upload-dialog-file-${id}-progressbar-value`}
            style={{ width: `${fileStatus.progression}%` }}
          ></div>
        </div>
      )}
    </div>
  );
};

const UploadPopoverStatuses = ({ fileStatuses, onCancel }: Props) => {
  if (fileStatuses.length === 0) {
    return null;
  }
  return (
    <div className="max-h-96 overflow-y-auto" data-test="upload-dialog-files-container">
      <div className="mx-4 my-2 flex flex-col justify-center divide-y divide-gray-200 dark:divide-gray-500">
        {fileStatuses.map((fs, index) => (
          <UploadPopoverFileStatus id={index} key={fs.file.name} fileStatus={fs} onCancel={onCancel} />
        ))}
      </div>
    </div>
  );
};

export default UploadPopoverStatuses;
