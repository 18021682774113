import { LoaderIcon } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { cn } from "utils/ui";

interface Props {
  isShown: boolean;
  className?: string;
}

const Loader = ({ isShown, className }: Props) => {
  const { t } = useTranslation();
  if (!isShown) {
    return null;
  }
  return (
    <div
      className={cn("mt-14 flex items-center justify-center space-x-4 dark:text-slate-200", className)}
      data-test="loader-container"
    >
      <LoaderIcon className="size-8" />
      <span className="text-color-primary text-xl font-bold">{t("common.loading")}</span>
    </div>
  );
};

export default Loader;
