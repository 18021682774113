import { HoverCard, HoverCardContent, HoverCardTrigger } from "components/ui/hover-card";
import { ScrollArea } from "components/ui/scroll-area";
import { CatSteps } from "pages/project/common/taskBase.typing";
import { cn } from "utils/ui";
import { SegmentProgress , SegmentStatusContainer } from "./SegmentProgress";

interface CatToolSegmentsProps {
  steps: CatSteps;
  tool: "memsource" | "xtm";
}

export function CatToolSegments({ steps, tool }: CatToolSegmentsProps) {
  const hasAllSegmentSubmitted = steps.progression === 100;

  return (
    <HoverCard openDelay={300}>
      <HoverCardTrigger className="cursor-pointer" data-test={`${tool}-segment-card-hover-trigger`}>
        <SegmentStatusContainer type={hasAllSegmentSubmitted ? "info" : "warning"} tool={tool}>
          <SegmentProgress
            totalDocuments={steps.details.length}
            progression={steps.progression}
            totalSegments={steps.totalSegments}
            doneSegments={steps.doneSegments}
          />
        </SegmentStatusContainer>
      </HoverCardTrigger>
      <HoverCardContent>
        <ScrollArea
          className={cn({
            "h-96": steps.details.length > 4,
          })}
        >
          <div className="flex flex-col gap-y-4" data-test={`${tool}-segment-card-hover-content`}>
            {steps.details.map((segment, index) => (
              <div
                key={segment.documentName}
                className="mx-4 grid grid-cols-1 place-content-stretch content-stretch pt-3 sm:grid-cols-5"
              >
                <div className="max-w-[180px] truncate sm:col-span-2" title={segment.documentName}>
                  {index + 1}. {segment.documentName}
                </div>
                <div className="sm:col-span-3">
                  <SegmentProgress
                    progression={segment.progression}
                    totalSegments={segment.totalSegments}
                    doneSegments={segment.doneSegments}
                  />
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>
      </HoverCardContent>
    </HoverCard>
  );
}
