import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CatToolProjectLinkDto, IdName, TaskDto, USER_TYPES, XtmProjectDto } from "api/api.typing";
import {
  fetchDeliveredFiles,
  fetchMemsourceJobsCompletion,
  fetchMemSourceProjectLinks,
  fetchMemSourceStepsProgress,
  fetchQAForm,
  fetchSupplierContacts,
  fetchTaskInstructions,
  fetchTasks,
  fetchTranslate5ProjectLinks,
  fetchXtmProject,
  fetchXtmStepsProgress,
  requestDeliverTask,
  requestDownloadPurchaseOrder,
  requestDownloadSupplierPreInvoice,
  updateSupplierContact,
} from "api/task.api";
import { AxiosError } from "axios";
import { toastContent } from "components/toastContent";
import { ListFilterParams } from "components/typings";
import { t } from "i18next";
import orderBy from "lodash/orderBy";
import { MemSourceProjectLink, QAFormType, TaskBaseFile, CatSteps } from "pages/project/common/taskBase.typing";
import { filterAndSortList, mapToTaskBase } from "pages/project/common/taskBase/taskBaseMapper";
import {
  mapToTaskStatus,
  Task,
  TASK_LIST_TYPE,
  TaskInstruction,
  TaskListType,
  TaskStatus,
} from "pages/project/task/task.typing";
import { useUser } from "query/user.query";
import { useCallback, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatServerError } from "utils/format";
import { LanguageType } from "utils/language";

const mapper = (task: TaskDto): Task => {
  const taskBase = mapToTaskBase(task);
  const shouldDisplayQAForm = taskBase.qaFormRequirement === 2;
  return {
    ...taskBase,
    shouldDisplayQAForm,
    status: task.status,
  };
};

export const useTaskList = (params?: ListFilterParams<Task>) => {
  const { i18n } = useTranslation();
  const { data: user } = useUser();
  const userType = useMemo(() => user?.userType ?? USER_TYPES.supplier, [user]);

  const {
    data = [],
    isLoading,
    error,
  } = useQuery<Task[], AxiosError>({
    queryKey: ["tasks", i18n.language, userType],
    queryFn: async () => {
      const response = await fetchTasks(userType, i18n.language as LanguageType);
      return response.acceptedTasks.map(mapper);
    },
    select: useCallback((selectedTasks: Task[]) => filterAndSortList(selectedTasks, params), [params]),
  });

  const hasTasks = !isLoading && data.length > 0;

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch task list");
      if (message) {
        toast.error(toastContent(t("translation:task.error-title.task-list"), message), {
          id: "task-fetch-error",
        });
      }
    }
  }, [error]);

  return { tasks: data, hasTasks, isLoading, error };
};

export function calculateProgress(current: number, total: number): number {
  // Ensure that current and total are non-negative
  current = Math.max(0, current);
  total = Math.max(0, total);

  if (total === 0) {
    return 0;
  }

  // Calculate the percentage of progression
  const percentage = Math.floor((current / total) * 100);

  // Ensure the result is between 0 and 100
  return Math.min(100, Math.max(0, percentage));
}

const useXtmStepProgress = (taskId: string, isShouldFetch: boolean) => {
  const { data, isFetching, error } = useQuery<CatSteps, AxiosError>({
    queryKey: ["xtm-step-progress", taskId],
    queryFn: async ({ signal }) => {
      const response = await fetchXtmStepsProgress(taskId, signal);
      const totalSegments = response.totalStepsProgress.totalSegments;
      const doneSegments = response.totalStepsProgress.segmentsDone;
      const result: CatSteps = {
        totalSegments,
        doneSegments,
        progression: calculateProgress(doneSegments, totalSegments),
        details: orderBy(
          response.stepsProgress.map((value) => ({
            documentName: value.fileName,
            doneSegments: value.segmentsDone,
            totalSegments: value.totalSegments,
            progression: calculateProgress(value.segmentsDone, value.totalSegments),
          })),
          "progression",
          "asc"
        ),
      };
      return result;
    },
    enabled: !!taskId && isShouldFetch,
    refetchOnWindowFocus: "always",
  });

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch xtp step progress");
      if (message) {
        toast.error(toastContent(t("translation:task.error-title.xtm-step-progress"), message), {
          id: "task-xtm-error",
        });
      }
    }
  }, [error]);

  return { steps: data, isFetching, error };
};

export function useDownloadPurchaseOrder() {
  const { error, isPending, mutate } = useMutation<void, AxiosError, string>({
    mutationFn: (taskId: string) => requestDownloadPurchaseOrder(taskId),
  });

  useEffect(() => {
    if (!error) {
      return;
    }
    const message =
      error.response?.status === 404
        ? "translation:fileDownload.notFoundPo"
        : "translation:fileDownload.failedDownloadPo";
    toast.error(toastContent(t("translation:fileDownload.po"), t(message)), {
      id: "download-po",
    });
  }, [error]);

  return { downloadPurchaseOrder: mutate, isLoading: isPending };
}

export function useDownloadSupplierPreInvoice() {
  const { error, isPending, mutate } = useMutation<void, AxiosError, number>({
    mutationFn: (preInvoiceId: number) => requestDownloadSupplierPreInvoice(preInvoiceId),
  });

  useEffect(() => {
    if (!error) {
      return;
    }
    toast.error(
      toastContent(t("translation:fileDownload.pre-invoice"), t("translation:fileDownload.preInvoiceFailed")),
      {
        id: "download-pre-invoice",
      }
    );
  }, [error]);

  return { downloadDownloadSupplierPreInvoice: mutate, isLoading: isPending };
}

export const useTaskListByType = (type: TaskListType, params?: ListFilterParams<Task>) => {
  const { tasks, isLoading, ...others } = useTaskList(params);

  const tasksByType = useMemo(() => {
    const taskStatus = mapToTaskStatus(type);
    if (!taskStatus) {
      return [];
    }
    return tasks.filter((task) => task.status === taskStatus) ?? [];
  }, [tasks, type]);

  return { ...others, isLoading, hasTasks: !isLoading && tasksByType.length > 0, tasks: tasksByType };
};

export const useTaskCounts = () => {
  const { tasks } = useTaskList();
  const onGoingCount = tasks.filter((task) => task.status === TaskStatus.OnGoing).length;
  const upComingCount = tasks.filter((task) => task.status === TaskStatus.UpComing).length;
  const deliveredCount = tasks.filter((task) => task.status === TaskStatus.Delivered).length;
  const completedCount = tasks.filter((task) => task.status === TaskStatus.Completed).length;
  return {
    ongoing: onGoingCount,
    upcoming: upComingCount,
    delivered: deliveredCount, // For supplier contacts
    validated: deliveredCount, // For customer contacts
    completed: completedCount,
  };
};

export const useTask = (selectedTaskId: string) => {
  // todo better is to call the api to get an offer/task by id
  const { tasks, isLoading } = useTaskList();
  const selectedTask = tasks.find((task) => task.id === selectedTaskId);
  return { isLoading, selectedTask };
};
export const useMemSourceProjectLinkList = (taskId: string, isMemsourceCatTool = true) => {
  const {
    data = [],
    isLoading,
    isFetching,
    error,
  } = useQuery<MemSourceProjectLink[], AxiosError>({
    queryKey: ["memsourceProjectLinks", taskId],
    queryFn: async ({ signal }) => {
      const links = await fetchMemSourceProjectLinks(taskId, signal);
      return orderBy(links, "CatProjectName");
    },
    enabled: !!taskId && isMemsourceCatTool,
  });
  const hasLinks = !isLoading && data.length > 0;

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch memsource project links");
      if (message)
        toast.error(toastContent(t("translation:task.error-title.memsource-links"), message), {
          id: "memsourceProjectLinks-fetch-error",
        });
    }
  }, [error]);

  return { links: data, hasLinks, isFetching, error };
};

export const useTranslate5ProjectLinkList = (taskId: string, isTranslate5CatTool = true) => {
  const {
    data = [],
    isLoading,
    isFetching,
    error,
  } = useQuery<CatToolProjectLinkDto[], AxiosError>({
    queryKey: ["translate5ProjectLinks", taskId],
    queryFn: async ({ signal }) => {
      const links = await fetchTranslate5ProjectLinks(taskId, signal);
      return orderBy(links, "ProjectName");
    },
    enabled: !!taskId && isTranslate5CatTool,
  });
  const hasLinks = !isLoading && data.length > 0;

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch translate5 project links");
      if (message)
        toast.error(toastContent(t("translation:task.error-title.translate5-links"), message), {
          id: "translate5ProjectLinks-fetch-error",
        });
    }
  }, [error]);

  return { links: data, hasLinks, isFetching, error };
};

export const useXtmData = (taskId: string, isTaskOngoing: boolean, isXtmCatTool: boolean) => {
  const { projects, isFetching: isXtmProjectFetching, error } = useXtmProjectList(taskId, isXtmCatTool);

  const isShouldFetchXtmStepProgress = isTaskOngoing && isXtmCatTool && projects.length > 0;

  const { steps, isFetching: isStepsFetching } = useXtmStepProgress(taskId, isShouldFetchXtmStepProgress);

  return { projects, steps, isFetching: isXtmProjectFetching || isStepsFetching, error };
};

const useXtmProjectList = (taskId: string, isXtmCatTool: boolean) => {
  const {
    data = [],
    isLoading,
    isFetching,
    error,
  } = useQuery<XtmProjectDto[], AxiosError>({
    queryKey: ["xtmProjectList", taskId],
    queryFn: ({ signal }) => fetchXtmProject(taskId, signal),
    enabled: !!taskId && isXtmCatTool,
  });
  const hasProject = !isLoading && data.length > 0;

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch xtm projects");
      if (message) {
        toast.error(toastContent(t("translation:task.error-title.xtm-project-list"), message), {
          id: "xtmProjects-fetch-error",
          duration: 5000,
        });
      }
    }
  }, [error]);

  return { projects: data, hasProject, isFetching, error };
};

export const useDeliveredFileList = (taskId: string) => {
  const {
    data = [],
    isFetching,
    isLoading,
    error,
  } = useQuery<TaskBaseFile[], AxiosError>({
    queryKey: ["deliveredFiles", taskId],
    queryFn: async () => fetchDeliveredFiles(taskId),
    enabled: !!taskId,
  });
  const hasFiles = !isLoading && data.length > 0;

  const hasDeliveryFiles = hasFiles && data.some((file) => file.fileType.toLowerCase() === "delivery");
  const hasNoteFiles = hasFiles && data.some((file) => file.fileType.toLowerCase() === "notes");

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch deliveredFiles");
      if (message) {
        toast.error(toastContent(t("translation:task.error-title.delivered-files"), message), {
          id: "deliveredFiles-fetch-error",
          duration: 5000,
        });
      }
    }
  }, [error]);

  return { files: data, hasFiles, hasDeliveryFiles, hasNoteFiles, isFetching, error };
};

type DeliverTaskMutationParam = {
  markAsDelivered: boolean;
  allFilesHaveBeenUploaded: boolean;
  comment?: string;
  qaFormRatings?: Array<{
    key: number;
    value: number | null;
  }>;
  isNoteDelivery?: boolean;
};

export const useDeliverTaskMutation = (taskId: string, catToolId?: number, isUserSupplier?: boolean) => {
  const { t: translation } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, ...others } = useMutation<unknown, AxiosError, DeliverTaskMutationParam>({
    mutationFn: ({
      qaFormRatings = undefined,
      comment,
      markAsDelivered,
      allFilesHaveBeenUploaded,
      isNoteDelivery = undefined,
    }) => {
      // fullDelivery only when userMark the task as delivered and all file have been uploaded
      return requestDeliverTask(
        taskId,
        markAsDelivered && allFilesHaveBeenUploaded,
        comment,
        qaFormRatings,
        catToolId,
        isNoteDelivery
      );
    },
    retry: false,
    onError: (error, form) => {
      toast.error(translation("task.modal.error"));
      datadogRum.addAction("deliver-task-failed", {
        taskId,
        comment: form.comment,
        qaFormRatings: form.qaFormRatings,
        error: { status: error.response?.status, message: error.message, response: error.response?.data },
      });
    },
    onSuccess: async (_, { markAsDelivered, allFilesHaveBeenUploaded }) => {
      // invalidate react-query cache
      await queryClient.invalidateQueries({ queryKey: ["deliveredFiles", taskId] });

      // task marked as fully delivered => redirect to new URL + inform user.
      if (markAsDelivered && allFilesHaveBeenUploaded) {
        await queryClient.cancelQueries({ queryKey: ["tasks"] });
        const allCachedTasks = queryClient.getQueriesData({ queryKey: ["tasks"] });
        allCachedTasks.forEach(([keys]) => {
          queryClient.setQueryData(keys, (oldData = []): Task[] => {
            const tasks = oldData as Task[];
            return tasks.map((task) =>
              task.id !== taskId
                ? task
                : {
                    ...task,
                    status: TaskStatus.Delivered,
                  }
            );
          });
        });

        // toast message and switch to delivered tasks
        toast.success(translation(isUserSupplier ? "task.modal.deliver.success" : "task.modal.validate.success"));
        navigate(`/project/task/list/${TASK_LIST_TYPE.delivered}/${taskId}?${params.toString()}`);
        return;
      }

      // user wanted to deliver the task but not file has been uploaded => tell the user to try again
      if (markAsDelivered && !allFilesHaveBeenUploaded) {
        toast.error(
          translation(
            isUserSupplier ? "task.modal.deliver.upload-deliver-failed" : "task.modal.validate.upload-validate-failed"
          )
        );
        return;
      }

      // all file have been uploaded by the user does not want to mark the task as done yet => inform him that everything is fine.
      if (allFilesHaveBeenUploaded) {
        toast.success(
          translation(isUserSupplier ? "task.modal.deliver.upload-success" : "task.modal.validate.upload-success")
        );
      } else {
        toast.error(
          translation(isUserSupplier ? "task.modal.deliver.upload-failed" : "task.modal.validate.upload-failed")
        );
      }
    },
  });

  const deliverTask = (
    markAsDelivered: boolean,
    allFilesHaveBeenUploaded: boolean,
    comment: DeliverTaskMutationParam["comment"] = "",
    qaFormRatings?: DeliverTaskMutationParam["qaFormRatings"],
    isNoteDelivery?: boolean
  ) => {
    mutate({ qaFormRatings, comment, markAsDelivered, allFilesHaveBeenUploaded, isNoteDelivery });
  };

  return { deliverTask, ...others };
};

export const useQAForm = (task: Task | undefined) => {
  const { data, isLoading, error } = useQuery<QAFormType, AxiosError>({
    queryKey: ["qaforms", task?.id],
    queryFn: async () => {
      const qaForm = await fetchQAForm(task?.id as string);
      const ratings = qaForm.answers;
      const titles = qaForm.criteriaGroups.flatMap(({ criteria }) => criteria);
      return { ratings, titles };
    },
    refetchOnWindowFocus: false,
    enabled: !!task?.id && task.shouldDisplayQAForm,
  });

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch QA Ratings");
      if (message) {
        toast.error(toastContent(t("translation:task.error-title.qa-form"), message), {
          id: "task-qa-form-error",
        });
      }
    }
  }, [error]);

  return { qaForm: data, isLoading, error };
};

export const useSupplierContactList = () => {
  const {
    data = [],
    isLoading,
    isFetching,
    error,
  } = useQuery<IdName[], AxiosError>({
    queryKey: ["SupplierContacts"],
    queryFn: async () => await fetchSupplierContacts(),
  });
  const hasSupplierContact = !isLoading && data.length > 0;

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch supplier contacts");
      if (message) {
        toast.error(toastContent(t("translation:task.error-title.supplier-contact"), message), {
          id: "SupplierContact-fetch-error",
          duration: 5000,
        });
      }
    }
  }, [error]);

  return { supplierContacts: data, hasSupplierContact, isFetching, error };
};

export const useUpdateSupplierContact = (taskId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (supplierContactId: number) => {
      await updateSupplierContact(taskId, supplierContactId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["tasks"] });
      toast.success(t("task.modal.update-field.supplier-contact.success"));
    },
    onError: (error) => {
      toast.error(t("task.modal.update-field.supplier-contact.error"));
      console.error(error);
    },
  });
};

export const useTaskInstructionList = (taskId: string) => {
  const { i18n } = useTranslation();
  const {
    data = [],
    isLoading,
    error,
  } = useQuery<TaskInstruction[], AxiosError>({
    queryKey: ["tasks", "instructions", taskId, i18n.language],
    queryFn: async ({ signal }: QueryFunctionContext) =>
      fetchTaskInstructions(taskId, i18n.language as LanguageType, signal),
  });

  const hasInstuctions = !isLoading && data.length > 0;

  useEffect(() => {
    if (error) {
      const message = formatServerError(error);
      datadogLogs.logger.error("failed to fetch instructions");
      if (message) {
        toast.error(toastContent(t("translation:task.error-title.task-instructions"), message), {
          id: "instruction-fetch-error",
        });
      }
    }
  }, [error]);

  return { instructions: data, hasInstuctions, isLoading, error };
};

export const useMemsourceProgressData = (taskId: string, isTaskOngoing: boolean, isMemSourceCatTool: boolean) => {
  const { data: steps, isFetching } = useQuery({
    queryKey: ["memsource-step-progress", taskId],
    queryFn: async ({ signal }) => {
      const response = await fetchMemSourceStepsProgress(taskId, signal);
      const totalSegments = response.totalStepsProgress.totalSegments;
      const doneSegments = response.totalStepsProgress.segmentsDone;
      const result: CatSteps = {
        totalSegments,
        doneSegments,
        progression: calculateProgress(doneSegments, totalSegments),
        details: orderBy(
          response.stepsProgress.map((value) => ({
            documentName: value.fileName,
            doneSegments: value.segmentsDone,
            totalSegments: value.totalSegments,
            progression: calculateProgress(value.segmentsDone, value.totalSegments),
          })),
          "progression",
          "asc"
        ),
      };
      return result;
    },
    enabled: !!taskId && isTaskOngoing && isMemSourceCatTool,
    refetchOnWindowFocus: "always",
  });

  return { steps, isFetching };
};

export const useMemsourceJobsCompletion = (
  taskId: string,
  isTaskOngoing: boolean,
  isMemSourceCatTool: boolean,
  hasTaskLinks: boolean,
  shouldDisplayQaForm: boolean
) => {
  const { data: completed, isFetching } = useQuery({
    queryKey: ["memsource-task-jobs-completion", taskId],
    queryFn: async ({ signal }) => {
      const response = await fetchMemsourceJobsCompletion(taskId, signal);
      return response.completed;
    },
    enabled: !!taskId && isTaskOngoing && isMemSourceCatTool && shouldDisplayQaForm && hasTaskLinks,
    refetchOnWindowFocus: "always",
  });

  return { completed, isFetching };
};
