import { Checkbox } from "components/CheckboxForm";
import { QAFormType } from "pages/project/common/taskBase.typing";
import { Fragment } from "react";
import { DeliverFormModel } from "pages/project/task/components/modals/useDeliverTaskForm";
import { StarIcon } from "lucide-react";

interface Props {
  data: QAFormType;
  qaFormRatings: DeliverFormModel["qaFormRatings"];
  isStarRating?: boolean;
  onqaFormRatingChange: (ratings: DeliverFormModel["qaFormRatings"]) => void;
}

function QAForm({ data, qaFormRatings, isStarRating, onqaFormRatingChange }: Props) {
  const handleOnchange = (titleId: number, updatedRatings: Array<{ key: number; value: boolean }>) => {
    onqaFormRatingChange(
      qaFormRatings.map((rate) =>
        rate.titleId === titleId
          ? {
              ...rate,
              ratings: updatedRatings,
            }
          : rate
      )
    );
  };

  return (
    <>
      <div className="my-4 grid grid-cols-8 gap-3">
        {isStarRating ? null : (
          <>
            <div className={`hidden text-sm sm:block ${isStarRating ? "col-span-3" : "col-span-4"}`} />
            {data.ratings.map(({ key: ratingId, value }) => {
              return (
                <div key={ratingId} className="mx-auto hidden text-xs font-medium sm:block">
                  {value}
                </div>
              );
            })}
          </>
        )}
        {qaFormRatings.map(({ title, titleId, ratings }) => {
          return (
            <Fragment key={titleId}>
              <div
                key={titleId}
                title={title}
                className={`flex items-center truncate text-sm ${isStarRating ? "col-span-3" : "col-span-4"}`}
              >
                {title}
              </div>
              <QAFormItem
                titleId={titleId}
                ratings={ratings}
                isStarRating={isStarRating}
                onChange={(updatedRatings) => handleOnchange(titleId, updatedRatings)}
              />
            </Fragment>
          );
        })}
      </div>
    </>
  );
}

function QAFormItem({
  ratings,
  titleId,
  isStarRating,
  onChange,
}: {
  ratings: Array<{ key: number; value: boolean }>;
  titleId: number;
  isStarRating?: boolean;
  onChange: (ratings: Array<{ key: number; value: boolean }>) => void;
}) {
  const handleOnChange = (ratingId: number, isChecked: boolean) => {
    const newValues = ratings.map(({ key }) =>
      ratingId === key
        ? { key, value: isChecked }
        : {
            key,
            value: false,
          }
    );
    onChange(newValues);
  };

  return isStarRating ? (
    <StarRating ratings={ratings} titleId={titleId} changeRatings={onChange}></StarRating>
  ) : (
    <>
      {ratings.map(({ key: ratingId, value }) => {
        return (
          <div key={ratingId} className="mx-auto flex">
            <Checkbox
              id={`ratings.${titleId}.${ratingId}.value`}
              checked={value}
              onChange={(event) => handleOnChange(ratingId, event.target.checked)}
            />
          </div>
        );
      })}
    </>
  );
}

function StarRating({
  ratings,
  titleId,
  changeRatings,
}: {
  ratings: Array<{ key: number; value: boolean; highlighted?: boolean; selected?: boolean }>;
  titleId: number;
  changeRatings: (ratings: Array<{ key: number; value: boolean }>) => void;
}) {
  const onHover = (ratingId: number, hoverIn: boolean) => {
    if (ratings.find((r) => r.key === ratingId)?.selected) return;

    const newValues = ratings.map(({ key, value, highlighted, selected }) => ({
      key,
      value,
      highlighted: selected ? highlighted : hoverIn ? ratingId >= key : false,
      selected,
    }));
    changeRatings(newValues);
  };

  const onChange = (ratingId: number, newValue: boolean) => {
    const newValues = ratings.map(({ key }) => ({
      key,
      value: ratingId === key ? newValue : false,
      selected: ratingId >= key ? newValue : false,
    }));

    changeRatings(newValues);
  };

  return (
    <>
      {ratings.map(({ key: ratingId, value, highlighted, selected }) => (
        <div key={ratingId} className="mx-auto">
          <button type="button" className="flex">
            <StarIcon
              id={`ratings.${titleId}.${ratingId}.value`}
              className={`size-10 ${selected ? "fill-yellow-400 stroke-none" : ""} ${
                highlighted ? "fill-yellow-400" : ""
              }`}
              onClick={() => onChange(ratingId, !value)}
              onMouseEnter={() => onHover(ratingId, true)}
              onMouseLeave={() => onHover(ratingId, false)}
            />
          </button>
        </div>
      ))}
    </>
  );
}

export default QAForm;
