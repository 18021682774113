import { ColumnDef, Table } from "@tanstack/react-table";
import { DataTable } from "components/DataTable";
import { DataTableFacetedDateFilter, DataTableFacetedFilter } from "components/DataTableFacetedFilter";
import HighlightText from "components/HighlightText";
import { Button } from "components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "components/ui/dropdown-menu";
import { Sheet, SheetContent } from "components/ui/sheet";
import { UrlParamsType, useUrlParams } from "hooks/useUrlParams";
import { LinkIcon, Loader2Icon, MenuIcon } from "lucide-react";
import { PreInvoicePreview } from "pages/pre-invoice/PreInvoicePreview";
import { PreInvoice } from "pages/pre-invoice/pre-invoice.typing";
import { useEntityPreInvoiceList, usePreInvoiceList } from "query/pre-invoice.query";
import { useDownloadSupplierPreInvoice } from "query/task.query";
import { ReactElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { filterDateRange } from "utils/datatable";

function PreInvoiceListPage() {
  const navigate = useNavigate();
  const [selectedPreInvoice, setSelectedPreInvoice] = useState<PreInvoice | null>(null);
  const { t } = useTranslation("translation");
  const { preInvoices } = usePreInvoiceList();
  const { downloadDownloadSupplierPreInvoice, isLoading: downloadIsLoading } = useDownloadSupplierPreInvoice();
  const {
    queryParams: { searchTerm, sortBy },
    setQueryParams,
  } = useUrlParams({
    localStorageKey: "pre_invoices_filters",
    defaultValues: {
      searchTerm: "",
      sortBy: [{ id: "issuedDate", desc: true }],
    } as UrlParamsType,
  });

  const columns = useMemo<ColumnDef<PreInvoice>[]>(
    () => [
      {
        id: "documentNumber",
        header: t("pre-invoice.columns.documentNumber"),
        enableSorting: true,
        enableHiding: false,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        accessorFn: (preInvoice) => preInvoice.documentNumber,
        cell(props) {
          return (
            <Button
              variant="link"
              onClick={() => setSelectedPreInvoice(props.row.original)}
              className="hover:cursor-pointer hover:underline"
            >
              <div className="flex flex-nowrap items-center gap-1">
                <LinkIcon className="size-4" />
                <HighlightText term={searchTerm} text={props.row.original.documentNumber?.toString() ?? "-"} />
              </div>
            </Button>
          );
        },
      },
      {
        id: "entityName",
        header: t("pre-invoice.columns.entityName"),
        enableSorting: true,
        enableGlobalFilter: false,
        enableColumnFilter: true,
        accessorFn: (preInvoice) => preInvoice.branchName,
        filterFn: (row, _, value) => {
          return value.includes(row.original.branchId.toString());
        },
        cell(props) {
          return props.row.original.branchName;
        },
      },
      {
        id: "issuedDate",
        header: t("pre-invoice.columns.issuedDate"),
        enableSorting: true,
        enableGlobalFilter: false,
        enableColumnFilter: true,
        accessorFn: (preInvoice) => preInvoice.issuedDate,
        cell(props) {
          return t("enum:dates.mediumDate", { date: props.row.original.issuedDate });
        },
        filterFn: filterDateRange,
      },
      {
        id: "reIssuedDate",
        header: t("pre-invoice.columns.reIssuedDate"),
        enableSorting: true,
        enableGlobalFilter: false,
        enableColumnFilter: true,
        accessorFn: (preInvoice) => preInvoice.reIssuedDate,
        cell(props) {
          const formattedDate = t("enum:dates.mediumDate", { date: props.row.original.reIssuedDate });
          return <HighlightText term={searchTerm} text={formattedDate} />;
        },
        filterFn: filterDateRange,
      },
      {
        id: "amount",
        header: t("pre-invoice.columns.total"),
        enableSorting: true,
        enableGlobalFilter: true,
        enableColumnFilter: false,
        accessorFn: (preInvoice) => preInvoice.price,
        cell(props) {
          const preInvoice = props.row.original;
          const total = t("enum:intlNumber", { val: preInvoice.price });
          const currencyCode = preInvoice.currencyCode;
          return <HighlightText term={searchTerm} text={`${total} ${currencyCode}`} />;
        },
      },
      {
        id: "action-column",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        enableColumnFilter: false,
        cell(props) {
          const preInvoiceId = props.row.original.id;
          return (
            <div className="flex justify-end">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" size="sm">
                    <MenuIcon className="size-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    disabled={downloadIsLoading}
                    onClick={() => downloadDownloadSupplierPreInvoice(preInvoiceId)}
                  >
                    <div className="flex flex-nowrap items-center gap-2">
                      {downloadIsLoading ? <Loader2Icon className="size-4 shrink-0 animate-spin" /> : null}
                      <span>{t("translation:pre-invoice.columns.exportToExcel")}</span>
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => navigate(`./detail/${preInvoiceId}`)}>
                    {t("translation:pre-invoice.columns.viewDetails")}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        },
      },
    ],
    [t, searchTerm, downloadIsLoading, navigate, downloadDownloadSupplierPreInvoice]
  );

  return (
    <div className="m-4">
      <div
        className="text-color-primary bg-color-base border-b border-slate-200 dark:border-slate-600"
        data-test="pre-invoice-container"
      >
        <div className="px-10 py-6">
          <h1 className="text-3xl font-medium leading-tight" data-test={`pre-invoice-title`}>
            <span>{t("pre-invoice.title")}</span>
          </h1>
          <p className="text-color-secondary text-sm">{t("pre-invoice.subTitle")}</p>
        </div>
      </div>
      <div>
        <DataTable
          uniqueName="pre-invoice"
          columns={columns}
          data={preInvoices}
          sortBy={sortBy}
          onSortBy={(sortBy) => setQueryParams({ sortBy })}
          searchTerm={searchTerm}
          onSearch={(searchTerm) => setQueryParams({ searchTerm })}
          facetedFilters={(table) => <FacetedFilters table={table} />}
        />
      </div>
      <Sheet open={!!selectedPreInvoice} onOpenChange={(open) => !open && setSelectedPreInvoice(null)}>
        <SheetContent side="bottom" className="h-full overflow-y-auto sm:h-3/4">
          {selectedPreInvoice ? <PreInvoicePreview preInvoice={selectedPreInvoice} /> : null}
        </SheetContent>
      </Sheet>
    </div>
  );
}

function FacetedFilters<TData>({ table }: { table: Table<TData> }): ReactElement {
  const { t } = useTranslation();
  const { entities } = useEntityPreInvoiceList();
  return (
    <>
      {entities.length > 0 && table.getColumn("entityName") ? (
        <DataTableFacetedFilter
          className="w-[350px]"
          column={table.getColumn("entityName")}
          title={t("pre-invoice.columns.entityName")}
          options={entities}
        />
      ) : null}
      {table.getColumn("issuedDate") ? (
        <DataTableFacetedDateFilter
          column={table.getColumn("issuedDate")}
          title={t("pre-invoice.columns.issuedDate")}
        />
      ) : null}
      {table.getColumn("reIssuedDate") ? (
        <DataTableFacetedDateFilter
          column={table.getColumn("reIssuedDate")}
          title={t("pre-invoice.columns.reIssuedDate")}
        />
      ) : null}
    </>
  );
}

export default PreInvoiceListPage;
