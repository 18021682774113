import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { create } from "zustand";
import ENV_PARAMS from "utils/environment";
import { t } from "i18next";
import { toast } from "react-hot-toast";

const initHubConnection = () => {
  const baseURl = ENV_PARAMS.DEV ? "" : ENV_PARAMS.PARTNER_PORTAL_API_SERVER_URL;
  return new HubConnectionBuilder().withUrl(`${baseURl}/api/tempo-hub/partner`).withAutomaticReconnect().build();
};

const startHubConnection = async (connection: HubConnection) => {
  try {
    await connection.start();
    console.log("connected to hub provider", connection.connectionId);
  } catch (e) {
    toast.error(t("translation:common.hub.failedToConnect"));
    console.error("Connection failed to the hub provider", e);
  }
};

interface HubConnectionState {
  connection: HubConnection | null;
  start: () => void;
  stopConnection: () => void;
}

export const useHubConnection = create<HubConnectionState>((set, get) => ({
  connection: null,
  start: async () => {
    if (get().connection?.connectionId) {
      return;
    }
    const newConnection = initHubConnection();
    await startHubConnection(newConnection);
    set({ connection: newConnection });
  },
  stopConnection: async () => {
    await get().connection?.stop();
  },
}));
