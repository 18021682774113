import { datadogRum } from "@datadog/browser-rum";
import { Dialog } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useAnonymousDeliverXtmTaskMutation } from "anonymous/api/xtm.api";
import { useAnonymousXtmTask } from "anonymous/query/xtm.task.query";
import { getXSRFToken } from "api/user.api";
import Button from "components/buttons/Button";
import Modal from "components/modal/Modal";
import { DeliverFormModel } from "pages/project/task/components/modals/useDeliverTaskForm";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  token: string;
  onClose?: () => void;
  onConfirm?: () => void;
}

const DeliverAnonymousXtmTaskModal = ({ show, token, onClose, onConfirm }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "task.modal.deliver" });
  const { isLoading: isTaskLoading, selectedTask } = useAnonymousXtmTask(token);
  const { deliver, reset, isPending } = useAnonymousDeliverXtmTaskForm(token);

  const handleOnClose = () => {
    reset();
    onClose?.();
  };

  useEffect(() => {
    datadogRum.addAction("fetch-XSRF");
    getXSRFToken().catch(() => Promise.resolve());
  }, []);

  if (isTaskLoading || !selectedTask) {
    return null;
  }

  return (
    <>
      <Modal isShown={show} onClose={handleOnClose}>
        <div>
          {/*Icon*/}
          <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="size-6 text-green-600" aria-hidden="true" />
          </div>
          {/*Header*/}
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title as="h3" className="text-color-primary text-lg font-medium leading-6 sm:text-3xl">
              <div>{t("title")}</div>
              <div>{selectedTask.taskCode}</div>
            </Dialog.Title>
            <div className="mt-6 flex flex-col text-center">
              <div className="text-base font-medium">{t("subTitle")}</div>
            </div>
          </div>

          {/*body*/}
          <div>
            <form className="mx-4 mt-6 flex flex-col space-y-5" onSubmit={deliver}>
              <div className="mt-5 grid grid-cols-2 gap-3 sm:mt-6 sm:grid-flow-row-dense">
                <Button type="reset" disabled={isPending} color="ternary" label={t("cancel")} onClick={handleOnClose} />
                <Button
                  type="submit"
                  disabled={isPending}
                  color="primary"
                  label={t("confirm")}
                  onClick={() => onConfirm?.()}
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default DeliverAnonymousXtmTaskModal;

const useAnonymousDeliverXtmTaskForm = (token: string) => {
  const { register, reset, handleSubmit } = useForm<DeliverFormModel>();

  const onReset = () => {
    reset();
  };

  const { deliverTask, ...others } = useAnonymousDeliverXtmTaskMutation(token);

  return {
    ...others,
    register,
    reset: onReset,
    deliver: handleSubmit(() => deliverTask()),
  };
};
